<template>
  <div>
    <iframe
      class="termsContainer"
      :srcdoc="htmlContent"
      frameborder="0"
      allowfullscreen
      scrolling="auto"
    ></iframe>
    <BottomFooter class="footer" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import BottomFooter from "../../components/BottomFooter.vue";

export default {
  components: {
    BottomFooter,
  },
  setup() {
    const htmlContent = ref("");

    // Fetch HTML content from S3 bucket
    const fetchHTMLContent = async () => {
      try {
        const response = await fetch(
          process.env.VUE_APP_MEDIA_API_URL + "documents/Terms+Of+Service.html"
        );
        if (response.ok) {
          htmlContent.value = await response.text();
        } else {
          console.error("Failed to fetch HTML content:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };

    onMounted(() => {
      fetchHTMLContent(); // Fetch HTML content when component is mounted
    });

    return {
      htmlContent,
    };
  },
};
</script>

<style scoped>
.footer {
  margin-top: 20px;
}

.termsContainer {
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden; /* Hide any overflow */
 
}
</style>
