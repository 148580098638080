<template>
  <div class="mainContainer mx-4">
    <div class="text-center">
      <p class="text-white titleText pt-3">{{ collection.name }}</p>
    </div>
    <div class="mt-4 mb-2 d-flex justify-center">
      <v-btn
        class="subtitleText seeButtonBottom px-5"
        @click="navigateToExplore"
        >see all</v-btn
      >
    </div>
    <div class="mt-3 pt-4 px-3">
      <div class="horizontalScroll">
        <div v-if="loading" class="mainProgressCircle">
          <v-progress-circular
            indeterminate
            color="rgb(255, 123, 0)"
            :size="50"
          ></v-progress-circular>
        </div>
        <div v-if="!loading" v-for="nft in nftCollections" :key="nft">
          <v-lazy
            class="mx-2"
            :options="{ threshold: 0.5 }"
            transition="fade-transition"
          >
            <NFTCard class="my-1" :nftData="nft" />
          </v-lazy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NFTCard from "./NFTCard.vue";
import { ref, onMounted, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";

export default {
  name: "CollectionSection",

  components: {
    NFTCard,
  },

  props: {
    collection: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const data = ref(props.collection);
    const nftCollections = ref([]);
    const collectionDetails = ref({});
    const { result, loading, error, refetch } = useQuery(
      gql`
        query dropsByCollectionPreview($collection: Int!) {
          dropsByCollectionPreview(collection: $collection) {
            id
            name
            image
            nfttokenid
            price
            royaltyPercentage
            royaltyType
            collection {
              id
              artist {
                name
                user {
                  profilePicture
                }
              }
            }
          }
        }
      `,
      {
        collection: Number(props.collection.id),
      }
    );

    watch(result, (value) => {
      nftCollections.value = value.dropsByCollectionPreview;
    });

    onMounted(() => {
      refetch();
    });

    const navigateToExplore = () => {
      router.push({ name: "Explore" });
    };

    return {
      data,
      collectionDetails,
      nftCollections,
      loading,
      navigateToExplore
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.rowContainer {
  position: relative;
  height: 450px;
}

.mainContainer {
  /* background: #0d0d0d;
  border-radius: 30px;
  height: auto;
  border: none;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  outline: none;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(20px); */
  max-width: 1700px;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* 100% of the viewport height */
  width: 100%;
}

.seeButtonBottom {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

.titleText {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.subtitleText {
  font-size: 18px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.horizontalScroll {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  overflow-x: scroll;
  height: 420px;
  width: 100%;
}
.horizontalScroll:not(:hover)::-webkit-scrollbar {
  display: none;
}
.horizontalScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #333;
}

.horizontalScroll::-webkit-scrollbar-track {
  background-color: #222;
}

.horizontalScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #4d4d4d;
}

.horizontalScroll::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.seeButton {
  border-radius: 25px;
  border-style: solid;
  border-width: 1px;
}

.backgroundSongCard {
  margin-left: -80px;
  filter: blur(5px);
}

@media screen and (max-width: 600px) {


  .rowContainer {
    position: relative;
    height: 330px;
  }
}
</style>
