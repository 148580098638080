<template>
  <v-main>
    <div v-if="isMainLoading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-if="!isMainLoading">
      <div class="contentContainer">
        <img
          :src="
            coverPicture ? coverPicture : 'https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/grad11.jpg'
          "
          class="backgroundCover"
        />

        <div class="bioContainer mt-10">
          <v-row class="d-flex align-center justify-center">
            <v-col class="d-flex">
              <img
                class="songImage"
                :src="
                  profilePicture
                    ? profilePicture
                    : 'https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/defaultProfile.svg'
                "
                alt="logo"
              />
              <!-- <v-btn
                v-if="isArtist"
                class="editIcon"
                variant="text"
                icon="mdi-cog-outline"
                @click="showSettings = true"
              >
              </v-btn> -->
            </v-col>
            <v-col cols="4" class="d-none d-md-flex mt-10">
              <div class="glassCard">
                <p class="subtitleText ml-3 mb-2 text-center">
                  Listen on the following platforms
                </p>
                <div class="d-flex" style="gap: 20px">
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/apple.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/spotify.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/Youtube.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/audiomack.png"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row align="center" justify="start" class="mt-2">
            <v-col cols="12" md="7">
              <div class="d-flex align-center">
                <p class="headingText mt-2">{{ artistDetails.name }}</p>
                <div class="d-flex ml-6">
                  <p
                    class="followerText widthContainer mt-3 d-flex"
                    @click="showFollowersPopup = true"
                  >
                    <span class="text-grey">
                      <v-icon size="21" class="mb-1 mr-2">mdi-account</v-icon>
                    </span>
                    <span>{{ followersResult.length }}</span>
                  </p>
                  <v-btn
                    v-if="!isFollower && !isArtist"
                    size="small"
                    class="followButton mt-3"
                    @click="followUser"
                  >
                    follow
                  </v-btn>
                  <v-btn
                    v-if="isFollower && !isArtist"
                    size="small"
                    variant="outlined"
                    class="followingButton mt-3"
                    @click="followUser"
                  >
                    following
                  </v-btn>
                </div>
              </div>
              <p class="descriptionText text-white widthContainer mt-3">
                {{ artistDetails.description }}
              </p>
            </v-col>
            <v-col cols="12" md="5" class="d-flex d-md-none">
              <div class="glassCard">
                <p class="subtitleText mb-2 text-center">
                  Listen on the following platforms
                </p>
                <div class="d-flex" style="gap: 20px">
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/apple.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/spotify.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/Youtube.png"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <img
                      class="platformImage"
                      src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/audiomack.png"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!isArtist">
          <div v-if="!loadings && artistCollections.length > 0">
            <p class="headingText2 text-center mt-10">Music Digital Assets Collections</p>
            <div
              v-for="collection in artistCollections"
              :key="collection"
              class="mx-4"
            >
              <CollectionSection
                :collection="collection"
                class="my-10 mx-auto"
              />
            </div>
          </div>
          <div v-if="!featuresLoading && featuredCollections.length > 0">
            <p class="headingText2 text-center mt-10 mb-5">
              Featured Music Digital Assets
            </p>
            <div v-if="!loadings && featuredCollections.length > 0">
              <div
                v-for="featuredCollection in featuredCollections"
                :key="featuredCollection"
                class="mx-4"
              >
                <CollectionSection
                  :collection="featuredCollection"
                  class="my-10 mx-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <v-card
          max-width="1200"
          flat
          color="transparent"
          class="mt-7"
          v-if="isArtist"
        >
          <v-tabs
            centered
            center-active
            color="rgb(255, 123, 0)"
            style="font-size: 22px"
            v-model="tab"
          >
            <v-tab class="tabStyle">Created collections</v-tab>
            <v-tab class="tabStyle">Purchased Assets</v-tab>
            <v-tab class="tabStyle">Payout and royalties</v-tab>
          </v-tabs>
        </v-card>
        <v-card
          max-width="1700"
          class="mx-auto"
          flat
          color="transparent"
          v-if="isArtist"
        >
          <v-tabs-items v-model="tab" class="mt-4">
            <v-tab-item v-if="tab == 0">
              <div v-if="!loadings && artistCollections.length > 0">
                <div
                  v-for="collection in artistCollections"
                  :key="collection"
                  class="mx-4"
                >
                  <CollectionSection
                    :collection="collection"
                    class="my-10 mx-auto"
                  />
                </div>
              </div>

              <div v-if="!featuresLoading && featuredCollections.length > 0">
                <p class="headingText2 text-center mt-10 mb-5">
                  Featured Digital Assets
                </p>
                <div v-if="!loadings && featuredCollections.length > 0">
                  <div
                    v-for="featuredCollection in featuredCollections"
                    :key="featuredCollection"
                    class="mx-4"
                  >
                    <CollectionSection
                      :collection="featuredCollection"
                      class="my-10 mx-auto"
                    />
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-keep-alive>
              <v-tab-item v-if="tab == 1">
                <div class="glassmorphism mx-3 pb-4">
                  <!-- <p class="headingText text-center">Your music NFTs</p> -->
                  <p
                    v-if="featuredCollections.length != 0"
                    class="nftSectionInfo mx-auto mt-2"
                  >
                    Below are the purchased music streaming royalties click to
                    view more information
                  </p>
                  <div v-if="!loadings" class="nftcollection-grid my-4">
                    <div v-for="nft in featuredCollections" :key="nft">
                      <NFTBlockchainCard class="mb-4" :nftData="nft" />
                    </div>
                  </div>
                  <div
                    v-if="featuredCollections.length == 0"
                    class="centeredTextContainer"
                  >
                    <p class="emptyText mx-auto">
                      You dont own any music digital assets, visit the explore page to
                      view available assets from your favourite artists
                    </p>
                  </div>

                  <div class="mb-4 d-flex justify-center">
                    <v-btn
                      class="subtitleText seeButtonBottom px-5"
                      @click="navigateToExplore"
                      >Explore</v-btn
                    >
                  </div>
                </div>
              </v-tab-item>
            </v-keep-alive>

            <v-tab-item v-if="tab == 2">
              <div class="mt-5 mx-3">
                <v-row>
                  <v-col>
                    <div class="glassCard2">
                      <p class="text-h5 ml-3 mb-2 text-center text-white">
                        Streaming Royalty Wallet
                      </p>
                      <p class="headingText text-white text-center">R 0</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <BottomFooter class="footer" />

      <v-dialog
        v-model="showSettings"
        max-width="600"
        class="editDialog mx-auto"
      >
        <v-card
          class="rounded-xl glassmorphismDialogue elevation-15 text-white"
        >
          <div class="mx-auto">
            <p class="text-h5 text-center mt-3">Settings</p>
            <div class="mx-4">
              <v-list lines="three" class="transparent-list text-white">
                <v-list-subheader class="text-white">Account</v-list-subheader>

                <v-list-item
                  @click="
                    showEditDialog = true;
                    showSettings = false;
                  "
                >
                  <template v-slot:prepend>
                    <v-avatar color="purple">
                      <v-icon color="white">mdi-account</v-icon>
                    </v-avatar>
                  </template>
                  <v-list-item-title class="custom-list-item-title"
                    >Profile</v-list-item-title
                  >
                  <v-list-item-subtitle class="custom-list-item-subtitle">
                    Update your profile and information
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item
                  @click="
                    showBankingDialog = true;
                    showSettings = false;
                  "
                >
                  <template v-slot:prepend>
                    <v-avatar color="blue">
                      <v-icon color="white">mdi-bank</v-icon>
                    </v-avatar>
                  </template>
                  <v-list-item-title class="custom-list-item-title"
                    >Banking details</v-list-item-title
                  >
                  <v-list-item-subtitle class="custom-list-item-subtitle">
                    Update banking details to receive royalty streaming share
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list
                lines="three"
                select-strategy="classic"
                class="transparent-list text-white"
              >
                <v-list-subheader class="text-white"
                  >Blockchain</v-list-subheader
                >

                <v-list-item value="notifications">
                  <template v-slot:prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>

                  <v-list-item-title class="custom-list-item-title"
                    >Blockchain view</v-list-item-title
                  >
                  <v-list-item-subtitle class="custom-list-item-subtitle">
                    Show web3 features and my blockchain info
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      v-model="showEditDialog"
      max-width="600"
      class="editDialog mx-auto text-center"
    >
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto" style="width: 100%">
          <img
            v-if="coverPlaceholder || coverPicture"
            class="bannerImg"
            :src="coverPlaceholder ? coverPlaceholder : coverPicture"
            alt="logo"
          />
          <img v-else class="bannerImg" src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/cover.png" alt="logo" />
          <div class="mx-auto text-center">
            <img
              v-if="profilePicture || profilePlaceholder"
              class="profileImg mx-auto text-center"
              :src="profilePlaceholder ? profilePlaceholder : profilePicture"
              alt="logo"
            />
            <img
              v-else
              class="profileImg"
              src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/defaultProfile.svg"
              alt="logo"
            />
          </div>

          <p class="text-h5 text-center mt-3">Edit profile</p>
          <div class="mx-4">
            <v-file-input
              label="Profile picture"
              variant="underlined"
              prepend-icon="mdi-camera"
              required
              @change="handleProfileFileChange"
            ></v-file-input>
            <v-file-input
              label="Background cover picture"
              variant="underlined"
              prepend-icon="mdi-camera"
              required
              @change="handleCoverFileChange"
            ></v-file-input>
            <div class="text-center">
              <v-btn class="mx-auto btn btn-3 mb-3" @click="updateProfle"
                >Update
                <v-progress-circular
                  v-if="updateLoading"
                  indeterminate
                  color="white"
                  :size="23"
                ></v-progress-circular>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFollowersPopup" max-width="600" height="400">
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto">
          <v-card-title class="text-white text-center mx-auto text-h4"
            >Followers</v-card-title
          >
          <v-divider class="mb-5"></v-divider>
          <div v-if="followersResult && followersResult.length > 0">
            <div v-for="follower in followersResult" :key="follower">
              <router-link
                :to="`/account/${follower.user.id}`"
                class="custom-link"
              >
                <div class="d-flex mb-2">
                  <img
                    class="followerImage"
                    :src="mediaUrl + follower.user.profilePicture"
                    alt="Follower Profile Picture"
                  />
                  <p class="titleText ml-4">{{ follower.user.username }}</p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <p class="text-center">No followers yet.</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import CollectionSection from "../components/CollectionSection.vue";
import BottomFooter from "../components/BottomFooter.vue";
import NFTCard from "../components/NFTCard.vue";
import { ref, watch, computed, onMounted } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import axios from "axios";
import Marketplace from "../Marketplace.json";
import { Network, Alchemy } from "alchemy-sdk";
import { ethers } from "ethers";
import { Magic } from "magic-sdk";

export default {
  name: "ArtistPage",

  components: {
    CollectionSection,
    BottomFooter,
    NFTCard,
  },

  setup() {
    const dialog = ref(false);
    const route = useRoute();
    const router = useRouter();
    const artistId = route.params.id;
    const loadings = ref(true);
    const artistCollections = ref([]);
    const artistDetails = ref({});
    const collectionDetails = ref({});

    const selectedProfileImage = ref(null);
    const selectedCoverImage = ref(null);
    const profilePicture = ref(null);
    const coverPicture = ref(null);
    const profilePlaceholder = ref(null);
    const coverPlaceholder = ref(null);

    const showFollowersPopup = ref(false);
    const showSettings = ref(false);
    const showEditDialog = ref(false);
    const featuredSongs = ref([]);

    const followersResult = ref([]);
    const isFollower = ref(false);
    const mediaUrl = process.env.VUE_APP_MEDIA_API_URL;

    const userAuth = useAuthenticationStore();
    const { userAuthToken } = storeToRefs(userAuth);
    const { isAuthenticated } = storeToRefs(userAuth);
    const { userId } = storeToRefs(userAuth);
    const { userType } = storeToRefs(userAuth);
    const { userTypeId } = storeToRefs(userAuth);
    const tab = ref(null);

    const bankDetails = ref({});
    const bankAccountName = ref("");
    const bankAccountNumber = ref("");
    const selectedBank = ref(null);
    const selectedAccountType = ref(null);
    const southAfricanBankCodes = ref([
      "ABSA",
      "FNB",
      "Nedbank" /* Add more bank codes */,
      "Capitec",
      "Standard Bank",
    ]);

    const accountTypes = ref([
      "Savings Account",
      "Checking Account",
      "Business Account",
      // Add more account types as needed
    ]);

    const updateBankLoading = ref(false);
    const purchasedNftLoading = ref(false);
    const featuredCollections = ref([]);

    const isArtist = ref(false);

    const form = ref({
      name: "",
      description: "",
      country: "",
      profilePicture: null,
      profileBackground: null,
    });

    onMounted(() => {
      refetch();
      refetchFollowers();
      refetchFeatures();
      refetchProfile();

      if (
        isAuthenticated.value &&
        userTypeId.value == artistId &&
        userType.value == "Artist"
      ) {
        isArtist.value = true;
      } else {
        isArtist.value = false;
      }
    });

    const { result: follower, refetch: refetchFollowers } = useQuery(
      gql`
        query artistSubs($id: Int!) {
          artistSubs(id: $id) {
            id
            user {
              id
              profilePicture
              username
            }
          }
        }
      `,
      {
        id: Number(artistId),
      }
    );

    watch(follower, (value) => {
      followersResult.value = value.artistSubs;
      const isUserFollower = followersResult.value.some(
        (sub) => sub.user.id === userId.value
      );
      isFollower.value = isUserFollower;
    });

    const {
      result: featuresResult,
      loading: featuresLoading,
      refetch: refetchFeatures,
    } = useQuery(
      gql`
        query collectionFeaturedArtist($artistId: Int!) {
          collectionFeaturedArtist(artistId: $artistId) {
            id
            name
            about
            image
            genre
          }
        }
      `,
      {
        artistId: Number(artistId),
      }
    );

    watch(featuresResult, (value) => {
      featuredCollections.value = value.collectionFeaturedArtist;
    });

    const { result, loading, error, refetch } = useQuery(
      gql`
        query collectionArtistId($artistId: Int!) {
          collectionArtistId(artistId: $artistId) {
            id
            name
            about
            image
            genre
          }
        }
      `,
      {
        artistId: Number(artistId),
      }
    );

    watch(result, (value) => {
      if (value.collectionArtistId.length > 0) {
        artistCollections.value = value.collectionArtistId;
      }
    });

    const {
      result: profileResults,
      loading: profileLoading,
      error: profileError,
      refetch: refetchProfile,
    } = useQuery(
      gql`
        query artistById($artId: Int!) {
          artistById(artId: $artId) {
            id
            name
            description
            country
            user {
              publicKey
              profilePicture
              profileBackground
              bankName
              bankAccount
              bankAccountType
              bankAccountHolder
              dateJoined
            }
          }
        }
      `,
      {
        artId: Number(artistId),
      }
    );

    watch(profileResults, (value) => {
      if (value.artistById) {
        artistDetails.value = value.artistById[0];
        coverPicture.value =
          process.env.VUE_APP_MEDIA_API_URL +
          artistDetails.value.user.profileBackground;
        profilePicture.value =
          process.env.VUE_APP_MEDIA_API_URL +
          artistDetails.value.user.profilePicture;
      }
    });

    const isMainLoading = computed(() => {
      if (loading.value || profileLoading.value || featuresLoading.value) {
        return true;
      } else {
        return false;
      }
    });

    const open = () => {
      dialog.value = true;
    };

    const close = () => {
      dialog.value = false;
    };

    const followUser = async () => {
      if (!isAuthenticated.value) {
        router.push({ path: "/authentication/login" });
      } else {
        await axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL +
              "fans/fan_subscribe/" +
              artistId,
            {},
            {
              headers: {
                Authorization: `Token ${userAuthToken.value}`,
              },
            }
          )
          .then((response) => {
            refetchFollowers();
          })
          .catch((error) => {
            console.error("Error following user:", error);
          });
      }
    };

    const handleCoverFileChange = (event) => {
      selectedCoverImage.value = event.target.files[0];
      coverPicture.value = URL.createObjectURL(selectedCoverImage.value);
      coverPlaceholder.value = URL.createObjectURL(selectedCoverImage.value);
    };

    const handleProfileFileChange = (event) => {
      selectedProfileImage.value = event.target.files[0];
      profilePicture.value = URL.createObjectURL(selectedProfileImage.value);
      profilePlaceholder.value = URL.createObjectURL(
        selectedProfileImage.value
      );
    };

    const getMyNFTs = async () => {
      purchasedNftLoading.value = true;
      try {
        const customNodeOptions = {
          rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
          chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
        };

        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
          network: customNodeOptions,
        });

        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();
        const signerAddress = signer.address;
        let market = JSON.parse(Marketplace);
        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );
        let myNFTS = await contract.getListingsForSeller(signerAddress);
        nftCount.value = myNFTS.length;
        const formattedData = formatNftDataForBatchCall(myNFTS);

        const metadataResponse = await alchemy.nft.getNftMetadataBatch(
          formattedData,
          {
            tokenUriTimeoutInMs: 5000,
            refreshCache: true,
          }
        );

        featuredCollections.value = metadataResponse;
        purchasedNftLoading.value = false;
      } catch (e) {
        purchasedNftLoading.value = false;
        console.error(e);
      }
    };

    return {
      dialog,
      form,
      open,
      close,
      artistDetails,
      artistCollections,
      loading,
      showFollowersPopup,
      followUser,
      followersResult,
      mediaUrl,
      isFollower,
      isMainLoading,
      featuredSongs,
      isArtist,
      tab,
      showSettings,
      showEditDialog,
      profilePicture,
      coverPicture,
      handleCoverFileChange,
      handleProfileFileChange,
      profilePlaceholder,
      coverPlaceholder,
      purchasedNftLoading,
      featuredCollections,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.contentContainer {
  position: relative;
  z-index: 9;
  min-height: 120vh;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}

.featuredContainerCard {
  background: #0d0d0d;
  border-radius: 30px;
  height: auto;
  border: none;
  /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.2); */
  outline: none;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(20px);
  max-width: 1700px;
}

.seeButtonBottom {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

.bioContainer {
  padding-left: 25px;
}

.descriptionText {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: "white";
}

.custom-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
  /* Optionally, you can remove other link styles like hover and focus effects */
}

.followerImage {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100%;
}

.backgroundCover {
  position: absolute;
  width: 100%;
  height: 370px;
  top: -180px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.editIcon {
  font-size: 25px;
  margin-left: 35px;
  color: white;
}

.followButton {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  width: 80px;
  text-align: center;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: rgb(17, 169, 235);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  margin-left: 15px;
}

.followingButton {
  font-family: "Poppins", sans-serif;
  border-radius: 25px;
  color: white;
  margin-left: 15px;
  box-shadow: 0 0 2px #eee;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.followerText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  color: white;
}
.submitbtn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;

  color: white;
  width: 200px;
  margin-top: 5px;
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.horizontalScroll {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  overflow-x: scroll;
  height: 420px;
  width: 100%;
}
.horizontalScroll:not(:hover)::-webkit-scrollbar {
  display: none;
}
.horizontalScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #333;
}

.horizontalScroll::-webkit-scrollbar-track {
  background-color: #222;
}

.horizontalScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #4d4d4d;
}

.horizontalScroll::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.glassmorphism {
  /* background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */

  background: #0d0d0d;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(50px);
}

.glassCard {
  background: #0d0d0d;
  width: fit-content;
  padding: 20px;
  height: 140px;
  border-radius: 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.platformImage {
  width: 60px;
  height: 60px;
}

.collectionContainer {
  height: 100%;
  margin-top: 430px;
}
.detailsContainer {
  height: auto;
  margin-top: 130px;
  z-index: 10;
}

.widthContainer {
  width: 90%;
}
.imageParent {
  /* position: relative;
  margin-left: 15px;
  margin-right: 15px; */
}
.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 90%;
  margin-inline: auto;
}

/* @media screen and (max-width: 400px) {
  .nftcollection-grid{
    grid-template-columns: repeat(1,1fr);

  }
} */

@media screen and (min-width: 380px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}
.headingText {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.headingText2 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: rgb(255, 123, 0);
}
.subtitleText {
  font-size: 17px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;

  color: white;
}

.editText {
  font-size: 20px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;

  color: white;
}

.glassEffect {
  margin-top: 50px;
  position: absolute;
  z-index: 0;
  background-color: #2f0050;
  filter: blur(50px);
  height: 100%;
  width: 120%;
  margin-left: -60px;
}
.songImage {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 15%;
}
.royaltystats {
  width: 100%;
  background: rgba(59, 59, 59, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.tabStyle {
  font-size: 22px;
  color: white;
}

.toolBarColor {
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
}

.visitBtn {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.priceColor {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
}

.Btn {
  background: #0d0d0d;
  width: fit-content;
  /* padding: 10px; */
  height: 80px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.editDialog {
  width: 600px;
}

.glassmorphismDialogue {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
  min-height: 300px;
  width: 600px;
}

.custom-list-item-title {
  font-size: 18px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
}

.custom-list-item-subtitle {
  font-size: 15px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
}

.transparent-list {
  background-color: transparent !important;
}

.glassCard2 {
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  padding: 20px;
  height: 130px;
  border-radius: 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(10px);
}

.bannerImg {
  width: 600px;
  height: 200px;
  object-fit: cover;
  /* display: block; */
  /* margin: 0 auto; */
}
.profileImg {
  margin-top: -80px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.centeredTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.emptyText {
  max-width: 400px;
  font-size: 25px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}

@media screen and (max-width: 600px) {

  .titleText {
    font-size: 16px;
  }

  .headingText {
    font-size: 25px;
  }

  .widthContainer {
    width: 75%;
  }

  .subtitleText {
    font-size: 14px;
  }

  .collectionContainer {
    height: 100%;
    margin-top: 280px;
  }
  .editDialog {
    width: 95%;
  }

  .glassmorphismDialogue {
    width: 95%;
  }

  .bannerImg {
    width: 100%;
  }
}
</style>
