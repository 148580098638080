<template>
  <div>
    <div
      :class="{
        open: isOpen,
        'chatbox-container': !isMobile || isOpen,
        'chatbox-container-mobile': isMobile && !isOpen,
      }"
    >
      <div @click="toggleChatbox">
        <img
          v-if="showAIAlert"
          class="assistantGuide"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/assistantGuide.svg"
          alt="logo"
        />
      </div>

      <div
        v-if="isOpen || !isMobile"
        class="chatbox-header glassmorphism2"
        @click="toggleChatbox"
      >
        <div class="d-flex justify-center">
          <img
            class="assistantImage"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/OIG.jfif"
            alt="logo"
          />
          <div class="chatbotTitle pl-3 pt-1">Gummint AI Assistant</div>
        </div>
        <v-icon>mdi-chat</v-icon>
      </div>

      <div
        v-if="!isOpen && isMobile"
        @click="toggleChatbox"
        class="mobileHeader"
      >
        <img class="assistantImageMobile" src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/OIG.jfif" alt="logo" />
      </div>
      <div class="chatbox-body">
        <div class="chatbox-messages" ref="messageList">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="{ bot: message.isBot }"
          >
            <div
              class="chatbox-message-text mt-4"
              :class="{ userText: (index + 1) % 2 == 0 }"
            >
              {{ message.text }}
            </div>
          </div>
        </div>
        <div class="chatbox-input">
          <form @submit.prevent="sendMessage">
            <input
              type="text"
              v-model="userMessage"
              placeholder="Type your message..."
            />
            <button type="submit"><v-icon>mdi-send</v-icon></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import openaiClient from "../openai";

export default {
  data() {
    return {
      isOpen: false,
      messages: [
        {
          text: "Hello my name is Max, how can I help you today?",
          isBot: true,
        },
      ],
      userMessage: "",
      headerText: "Chat with our AI",
      iconClass: "mdi-accounts",
      showAIAlert: true,
    };
  },

  computed: {
    isMobile() {
      const mediaQuery = window.matchMedia("(max-width: 767px)");
      return mediaQuery.matches;
    },
  },

  methods: {
    async sendMessage() {
      const userMessage = this.userMessage.trim();
      if (!userMessage) {
        return;
      }

      this.messages.push({
        text: userMessage,
        isBot: false,
      });

      openaiClient
        .createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "Your name is Max, you are a helpful assistant for gummint which is music nft marketplace web application, holders of the nfts receive a share of the digital streaming royalties of the song per percentage which the nft represents. How is works for artists is that the artist let us distribute the song to digital streaming platforms, then we mint a number of music nfts for the song each representing a percentage share of the digital streaming royalies from the song which is set by artist. The artist makes money from nft sells and a share of the secondary sells as well. As an assistant you answer questions related only to gummint, music , nfts, web3, cryptocurrency and nothing else besides these topics.",
            },
            { role: "user", content: `${userMessage}` },
          ],
        })
        .then((result) => {
          const botMessage = result.data.choices[0].message.content.trim();
          this.messages.push({
            text: botMessage,
            isBot: true,
          });
        });

      this.userMessage = "";
      this.$nextTick(() => {
        const messages = this.$refs.messageList;
        if (messages) {
          messages.scrollTop = messages.scrollHeight;
        }
        this.$refs.userInput.focus();
      });
    },

    toggleChatbox() {
      this.showAIAlert = false;
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        const messages = this.$refs.messageList;
        if (messages) {
          messages.scrollTop = messages.scrollHeight;
        }
        this.$refs.userInput.focus();
      });
    },
  },
};
</script>
<style scoped>
.chatbox-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 360px;
  z-index: 9;
  border-radius: 10px;
  background: linear-gradient(
    108.46deg,
    rgba(0, 0, 0, 0.32) 0%,
    rgba(0, 0, 0, 0.53) 100%
  );
  backdrop-filter: blur(20px);
  /* overflow-y: auto; */
  transform: translateY(90%);
  transition: transform 0.3s ease-in-out;
}

.chatbox-container-mobile {
  position: fixed;
  bottom: 20px;
  left: 330px;
  width: 360px;
  z-index: 9;
  transform: translateY(90%);
  transition: transform 0.3s ease-in-out;
}
.glassmorphism {
  /* background: #0d0d0d; */
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(20px);
}

.chatbox-container.open {
  transform: translateY(0);
}

.chatbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #252525;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.chatbox-header-icon {
  font-size: 20px;
}

.chatbox-body {
  height: 360px;
  display: flex;
  flex-direction: column;
}

.chatbox-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.assistantImage {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.assistantImageMobile {
  height: 45px;
  width: 45px;
  margin-bottom: 15px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: #a259ff;
}

.assistantGuide {
  position: absolute;
  margin-top: -130px;
  margin-left: -170px;
  height: 180px;
  width: 180px;
  border-radius: 50%;
}

.chatbotTitle {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.chatbox-message {
  display: flex;
  margin-bottom: 10px;
}

.chatbox-message-text {
  padding: 8px;
  border-radius: 5px;
  background-color: #ebebeb;
  max-width: 80%;
}

.message-bubble {
  display: inline-block;
  position: relative;
  padding: 30px;
  border-radius: 20px;
  background-color: #dcf8c6;
  margin-right: 10px;
  max-width: 80%;
  right: -1200px;
  top: -80px;
  z-index: 9;
}

/* .message-bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 20px 15px 0;
  border-color: transparent #dcf8c6;
  display: block;
  width: 0;
  z-index: 9;
  left: -15px;
  top: 10px;
} */

.userText {
  margin-left: 100px;
}

.chatbox-input {
  padding: 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid #c9c9c9;
}

.chatbox-input form {
  display: flex;
  flex: 1;
}

.chatbox-input input[type="text"] {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.chatbox-input button {
  border: none;
  background-color: transparent;
  color: #a259ff;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.chatbox-input button:hover {
  color: #ffffff;
}
</style>
