<template>
  <v-card
    max-height="450"
    max-width="250"
    class="playcard elevation no-padding"
    @click="navigateToNFT(song.collection.id, song.id, song.nfttokenid)"
  >
    <div v-if="currentNftLoading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="mx-auto text-center">
        <img class="songImage" :src="imageUrl" alt="logo" />
      </div>
      <div class="pl-4 pr-1 pb-3" style="width: 100%">
        <div class="d-flex my-1" style="gap: 5px">
          <img
            class="artistImage"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
            alt="logo"
          />
          <p class="text-white subText mt-2" style="opacity: 0.6">Gummint</p>
        </div>
        <p class="text-white titleText truncate">{{ song.name }}</p>
        <p class="text-white subText" style="opacity: 0.8">
          {{ song.royaltyType }} share: {{ song.royaltyPercentage }}%
        </p>
        <!-- <p class="text-white subText" style="opacity: 0.6">Terms: 30 years</p> -->
      </div>
      <v-card-actions class="d-flex justify-end" style="padding: 0; margin-bottom: -5px;">
        <v-btn block class="btn text-center" >
          <span v-if="nftMarketplaceDetails.listedForSale"
            >R {{ song.price }}</span
          >
          <span v-if="!nftMarketplaceDetails.listedForSale">Sold</span>
          <!-- <p>(R500)</p> -->
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import router from "../router";
import { ethers } from "ethers";
import Marketplace from "../Marketplace.json";

export default {
  name: "NFTCard",
  props: {
    nftData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentNftLoading = ref(false);
    const nftMarketplaceDetails = ref({});
    const song = ref(props.nftData);
    let imageUrl = process.env.VUE_APP_MEDIA_API_URL + props.nftData.image;
    const price = ref(null);
    const image = ref(imageUrl);
    const maxLength = 16;
    // const truncatedText = computed(function () {
    //   return metadata.value.nftDescription.length > maxLength
    //     ? metadata.value.nftDescription.substring(0, maxLength) + "..."
    //     : metadata.value.nftDescription;
    // });
    onMounted(() => {
      getCurrentNFT(props.nftData.nfttokenid,3,2000);
    });

    async function getCurrentNFT(token_id, retries, delay) {
      currentNftLoading.value = true;
      try {
        const provider = new ethers.JsonRpcProvider(
          process.env.VUE_APP_ALCHEMY_API_URL
        );
        let market = JSON.parse(Marketplace);
        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );

        nftMarketplaceDetails.value = await contract.getListing(token_id);
        currentNftLoading.value = false;
      } catch (error) {
        if (retries > 0) {
          console.log(`Retrying... ${retries} retries left`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return getCurrentNFT(token_id, retries - 1, delay);
        } else {
          currentNftLoading.value = false;
          console.log(error);
        }
      }
    }

    const navigateToNFT = (collectionId, nftBackendId, nftTokenId) => {
      router.push({
        path: `/collection/${collectionId}/${nftBackendId}/${nftTokenId}`,
      });
    };

    return {
      navigateToNFT,
      image,
      song,
      imageUrl,
      nftMarketplaceDetails,
      currentNftLoading,
      // truncatedText,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.no-padding {
  padding: 0 !important;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px; /* 100% of the viewport height */
  width: 250px;
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}
.playcard {
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 180px;
  width: 180px;
  object-fit: cover;
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.gradientColor {
  color: #57f2cc;
}
.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: 5px;
  /* box-shadow: 0 0 2px #eee; */
  /* border-radius: 20px; */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.playbutton {
  font-size: 60px;
}

.artistImage {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    max-height: 320px;
  }
  .mainProgressCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px; /* 100% of the viewport height */
    width: 170px;
  }

  .songImage {
    height: 160px;
    width: 160px;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 14px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}

/* .title {
    font-size: 30px;
  } */
</style>
