<template>
  <v-bottom-navigation v-model="currentRoute" class="glassmorphism" grow>
    <v-btn
      v-for="(item, index) in menuItems"
      :key="index"
      :value="item.to.name"
      @click="handleClick(item)"
       class="custom-btn"
    >
      <v-icon>{{ item.icon }}</v-icon>
      {{ item.title }}
    </v-btn>
  </v-bottom-navigation>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import AuthManager from "../authentication/authentication";

const router = useRouter();
const route = useRoute();
const currentRoute = ref(route.name);
const userAuth = useAuthenticationStore();
const { accounts } = storeToRefs(userAuth);
const authManager = new AuthManager();

const menuItems = [
  { title: "Home", icon: "mdi-home", to: { name: "Home" } },
  { title: "Explore", icon: "mdi-folder-play-outline", to: { name: "Explore" } },
  { title: "Tickets", icon: "mdi-ticket", to: { name: "TicketsHome" } },
  { title: "Account", icon: "mdi-account", to: { name: "AccountPage" }, requiresAuth: true },
];

const isAuthenticated = async () => {
  return await authManager.isLoggedIn();
};

const handleClick = async (item) => {
  if (item.requiresAuth && !(await isAuthenticated())) {
    router.push({ name: "Login" });
  } else if (item.title === "Account" && await isAuthenticated()) {
    // Check if accounts.value exists and is not empty
    if (accounts.value && accounts.value.length > 0) {
      router.push({ name: "AccountPage", params: { id: accounts.value} });
    } else {
      router.push({ name: "Login" });
    }
  } else {
    router.push(item.to);
  }
};

watch(route, (newRoute) => {
  currentRoute.value = newRoute.name;
});
</script>

<style scoped>
.glassmorphism {
  background: rgba(30, 30, 30, 0.7) !important;
  backdrop-filter: blur(10px) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37) !important;
}

.custom-btn {
  color: #ffffff !important; /* Light purple color */
  transition: opacity 0.3s ease, color 0.3s ease !important;
}

.custom-btn .v-icon {
  color: #ffffff !important; /* Light purple color for icons */
}

.v-btn--active.custom-btn {
  opacity: 1;
  background-color: rgba(179, 136, 255, 0.1) !important; /* Light purple background for active button */
  color: #7C4DFF !important; /* Darker purple for active text */
}

.v-btn--active.custom-btn .v-icon {
  color: #7C4DFF !important; /* Darker purple for active icon */
}

@media (min-width: 960px) {
  .v-bottom-navigation {
    height: 56px !important;
    max-width: 600px !important;
    margin: 0 auto !important;
    border-radius: 28px !important;
    bottom: 20px !important;
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
</style>