<template>
  <v-card max-height="420" max-width="250" class="playcard elevation">
    <img class="songImage" :src="nftdata.image" alt="logo" />
    <div class="pl-4 pr-1 pb-3" style="width: 100%">
      <div class="d-flex my-2" style="gap: 10px">
        <img
          class="artistImage"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
          alt="logo"
        />
        <p class="text-white subText" style="opacity: 0.6">
          {{ nftdata.artist1 }}
        </p>
      </div>
      <p class="text-white titleText">{{ truncatedText }}</p>
      <p class="text-white subText" style="opacity: 0.7">
        {{ nftdata.royaltyType }} Royalty: {{ nftdata.royaltyPercentage }}%
      </p>
    </div>
    <v-card-actions
      class="d-flex justify-end"
      style="padding: 0; margin-bottom: -5px"
    >
      <slot name="button">
        <v-btn
          block
          class="btn px-4 text-center"
          @click="navigateToUser(nftdata.tokenId)"
          >View</v-btn
        >
      </slot>

      <!-- <slot name="button">
        <v-btn
          class="btn px-4 text-center"
          @click="navigateToUser(data.nfttoken)"
          ><v-icon class="mr-1">mdi-cart-outline</v-icon> R800</v-btn
        >
      </slot> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import router from "../router";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

export default {
  name: "NFTCard",
  props: {
    nftData: {
      type: Object,
      required: true,
    },

    collectionId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const data = ref(props.nftData);
    const collectionId = ref(null);
    const nftBackendId = ref(null);
    const nftdata = ref(data.value);
    console.log(nftdata.value);
    const metadata = ref(nftdata.value.rawMetadata);
    const maxLength = ref(16);
    const truncatedText = computed(function () {
      return nftdata.value.name?.length > maxLength.value
        ? nftdata.value.name?.substring(0, maxLength.value) + "..."
        : nftdata.value.name;
    });

    const {
      result: songResult,
      loading: songLoading,
      error: songError,
      refetch: songRefetch,
    } = useQuery(
      gql`
        query dropsByNfttokenid($id: Int!) {
          dropsByNfttokenid(id: $id) {
            id
            collection {
              id
            }
          }
        }
      `,
      {
        id: Number(nftdata.value.tokenId),
      }
    );

    watch(songResult, (value) => {
      const collection = value.dropsByNfttokenid[0].collection;
      collectionId.value = collection.id;
      nftBackendId.value = value.dropsByNfttokenid[0].id;
    });

    onMounted(() => {
      songRefetch();
    });

    const navigateToUser = (id) => {
      router.push({
        path: `/collection/${collectionId.value}/${nftBackendId.value}/${id}`,
      });
    };

    // console.log(JSON.parse(nftdata.value));

    return {
      nftdata,
      metadata,
      navigateToUser,
      truncatedText,
      maxLength,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}
.playcard {
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;

  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 220px;
  width: 100%;
  object-fit: cover;
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.gradientColor {
  color: #57f2cc;
}
.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.playbutton {
  font-size: 60px;
}

.artistImage {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    height: 310px;
  }

  .songImage {
    height: 160px;
    width: 100%;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 16px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}

/* .title {
  font-size: 30px;
} */
</style>
