<template>
  <v-app>
    <v-main class="appbackground">
      <div class="glassEffect"></div>

      <div class="contentContainer">
        <div class="text-center my-14">
          <p class="text-white titleText">Explore music</p>
          <p class="text-white subtitleText">
            Explore all the music NFT releases from every genre
          </p>
        </div>
        <div class="mx-auto exploreContainer">
          <h1>Notify Page</h1>
        </div>
      </div>
      <BottomFooter class="footer" />
    </v-main>
  </v-app>
</template>

<script>
import ExploreTab from "../components/ExploreTab.vue";
import BottomFooter from "../components/BottomFooter.vue";

export default {
  name: "NotifyPage",

  components: {
    ExploreTab,
    BottomFooter,
  },

  data: () => ({}),

  created() {
    console.log("The component has been created!");
    // You can perform any actions you want here.
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.titleText {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.contentContainer {
  position: relative;
  z-index: 9;
  flex: 1;
  min-height: 100vh;
}

.exploreContainer {
  min-height: 600px;
}

.glassEffect {
  margin-top: 50px;
  position: absolute;
  z-index: 0;
  background-color: #2f0050;
  filter: blur(20px);
  height: 100%;
  width: 120%;
  margin-left: -60px;
}

.glassEffect2 {
  margin-top: 20px;
  position: absolute;
  background: #0d0d0d;
  border-radius: 30px;
  border: none;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  outline: none;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(20px);
  filter: blur(50px);
  z-index: 0;
  height: 100%;
  width: 120%;
  margin-left: -60px;
}

.subtitleText {
  font-size: 30px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}
/* .appbackground {
    background-color: #11001d;
  } */

.appbackground {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  height: auto;
 
  box-shadow: 0px 106px 218px #000000;
}
</style>
