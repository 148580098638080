<!-- src/components/AccountEarningsOverview.vue -->
<template>
    <div>
      <v-card class="earnings-overview-card">
        <v-card-title class="text-h5 font-weight-bold mb-4 text-white">Your Earnings Overview</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" class="stat-item">
              <div class="stat-label"><v-icon left color="#b0b0b0">mdi-play-circle</v-icon> Total Streams</div>
              <div class="stat-value">{{ totalStreams.toLocaleString() }}</div>
            </v-col>
            <v-col cols="12" sm="4" class="stat-item">
              <div class="stat-label"><v-icon left color="#b0b0b0">mdi-currency-usd</v-icon> Total Earnings</div>
              <div class="stat-value">R{{ totalEarnings.toFixed(2) }}</div>
            </v-col>
            <v-col cols="12" sm="4" class="stat-item">
              <div class="stat-label"><v-icon left color="#b0b0b0">mdi-image-multiple</v-icon> Owned Digital Assets</div>
              <div class="stat-value">{{ ownedNFTsCount }}</div>
            </v-col>
          </v-row>
  
          <v-divider class="my-6 divider"></v-divider>
  
          <div class="withdraw-section">
            <h3 class="text-h6 font-weight-bold mb-4 text-white">Withdraw Earnings</h3>
            <p class="current-balance mb-2">
              Current Balance: <span class="font-weight-bold">R {{ totalEarnings.toFixed(2) }}</span>
            </p>
            <p v-if="totalEarnings >= 200" class="status-message success--text">
              You can now withdraw your earnings!
            </p>
            <p v-else class="status-message warning--text">
              You need R{{ (200 - totalEarnings).toFixed(2) }} more to withdraw.
            </p>
            <v-btn
              class="withdraw-btn mt-4"
              :disabled="totalEarnings < 200"
              @click="showWithdrawDialog"
              elevation="2"
            >
              Withdraw R{{ totalEarnings.toFixed(2) }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
  
      <!-- Withdrawal Dialog -->
      <v-dialog v-model="withdrawDialog" max-width="400px" overlay-opacity="0.8">
        <v-card class="glassmorphism-dialog">
          <v-card-text class="text-center pa-6 ">
            <v-img
              src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/payment.gif"
              alt="Processing"
              width="150"
              class="mx-auto mb-4"
            ></v-img>
            <h2 class="text-h5 font-weight-bold text-white mb-4">Processing Withdrawal</h2>
            <p class="text-white">Please wait while we process your withdrawal of R{{ totalEarnings.toFixed(2) }}.</p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      totalStreams: {
        type: Number,
        required: true
      },
      totalEarnings: {
        type: Number,
        required: true
      },
      ownedNFTsCount: {
        type: Number,
        required: true
      }
    },
    emits: ['withdraw'],
    data() {
      return {
        withdrawDialog: false
      }
    },
    methods: {
      showWithdrawDialog() {
        this.withdrawDialog = true;
        // Simulate processing time
        setTimeout(() => {
          this.withdrawDialog = false;
          this.$emit('withdraw', this.totalEarnings);
        }, 3000);
      }
    }
  }
  </script>
  
  <style scoped>
  
  .glassmorphism-dialog {
    background: rgba(58, 58, 58, 0.7) !important;
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  .stat-label {
    font-size: 0.9rem;
    color: #b0b0b0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .stat-label .v-icon {
    margin-right: 4px;
  }

  .earnings-overview-card {
    background: linear-gradient(135deg, #2a2a2a, #3a3a3a);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .stat-item {
    text-align: center;
    padding: 16px;
  }
  
  .stat-label {
    font-size: 0.9rem;
    color: #b0b0b0;
    margin-bottom: 8px;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .withdraw-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 24px;
    margin-top: 16px;
  }
  
  .current-balance {
    font-size: 1.1rem;
    color: #e0e0e0;
  }
  
  .status-message {
    font-size: 0.9rem;
    margin-top: 8px;
  }
  
  .status-message.success--text {
    color: #4CAF50 !important;
  }
  
  .status-message.warning--text {
    color: #FFA000 !important;
  }
  
  .withdraw-btn {
    background: linear-gradient(90deg, #FF9800, #F57C00);
    color: #ffffff;
    font-weight: bold;
    font-size: 17px;
    text-transform: none;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
  }
  
  .withdraw-btn:hover:not(:disabled) {
    opacity: 0.9;
    transform: translateY(-2px);
  }
  
  .withdraw-btn:disabled {
    background: linear-gradient(90deg, #FF9800, #F57C00);
    color: #ffffff;
    opacity: 0.9;
  }
  </style>