<template>
  <div class="recentSection">
    <div class="text-center">
      <p class="text-white titleText pt-3">Recent Music Digital Assets</p>
    </div>
    <div class="mt-4 mb-10 d-flex justify-center">
      <v-btn
        class="subtitleText seeButtonBottom px-5"
        @click="navigateToExplore"
        >see all</v-btn
      >
    </div>

    <v-row class="mx-3 pt-4">
      <div class="horizontalScroll">
        <div v-if="loading" class="mainProgressCircle">
          <v-progress-circular
            indeterminate
            color="rgb(255, 123, 0)"
            :size="50"
          ></v-progress-circular>
        </div>
        <div v-if="!loading" v-for="song in nftSongs" :key="song">
          <v-lazy
            :min-height="200"
            :options="{ threshold: 0.5 }"
            transition="fade-transition"
          >
            <RecentDropCard :nftData="song" class="my-4"></RecentDropCard>
          </v-lazy>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import NFTCard from "./NFTCard.vue";
import CollectionCard from "./CollectionCard.vue";
import RecentDropCard from "./RecentDropCard.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";

export default {
  name: "RecentDrops",

  components: {
    NFTCard,
    CollectionCard,
    RecentDropCard,
  },
  setup() {
    const allNFTs = ref([]);
    const testNFT = ref({});
    const loadings = ref(true);
    const nftSongs = ref([]);
    const maxLength = ref(16);

    onMounted(() => {
      refetch();
    });

    const { result, loading, error, refetch } = useQuery(
      gql`
        query recentDrops {
          recentDrops {
            id
            name
            nfttokenid
            image
            price
            royaltyPercentage
            royaltyType
            collection {
              id
            }
          }
        }
      `
    );

    watch(result, (value) => {
      nftSongs.value = value.recentDrops;
    });

    //shorten song name to fit on the card
    const truncatedName = computed(function (_nftSong) {
      if (!_nftSong || _nftSong.length === 0) {
        return [];
      } else {
        return _nftSong.name.length > maxLength.value
          ? _nftSong.name.substring(0, maxLength.value) + "..."
          : _nftSong.name;
      }
    });

    const navigateToUser = (id) => {
      router.push({ path: `/collection/${id}` });
    };

    const navigateToExplore = () => {
      router.push({ path: "/explore" });
    };
    // async function getAllNFTs() {
    //   //After adding your Hardhat network to your metamask, this code will get providers and signers
    //   // const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   // const signer = provider.getSigner();
    //   // //Pull the deployed contract instance
    //   // let market = JSON.parse(Marketplace);
    //   // let contract = new ethers.Contract(market.address, market.abi, signer);
    //   // //create an NFT Token
    //   // let transaction = await contract.getMyMarketNfts();
    //   // console.log(transaction);
    //   // //Fetch all the details of every NFT from the contract and display
    //   // const items = await Promise.all(
    //   //   transaction.map(async (i) => {
    //   //     const tokenURI = await contract.tokenURI(i.tokenId);
    //   //     console.log(tokenURI);
    //   //     let meta = await axios.get(tokenURI, {
    //   //       headers: {
    //   //         Accept: "text/plain",
    //   //       },
    //   //     });
    //   //     meta = meta.data;

    //   //     let price = ethers.utils.formatUnits(i.price.toString(), "ether");
    //   //     let item = {
    //   //       price,
    //   //       tokenId: i.tokenId.toNumber(),
    //   //       seller: i.seller,
    //   //       owner: i.owner,
    //   //       image: meta.image,
    //   //       nftName: meta.nftName,
    //   //       nftDescription: meta.nftDescription,
    //   //     };
    //   //     return item;
    //   //   })
    //   // );

    //   let NFTcontractData = JSON.parse(CreateNft);

    //   let address = NFTcontractData.address;

    //   const chain = EvmChain.MUMBAI;

    //   const response = await Moralis.EvmApi.nft.getContractNFTs({
    //     address,
    //     chain,
    //   });
    //   // console.log(response.toJSON());
    //   var data = response.toJSON();
    //   // testNFT.value = items[1];
    //   allNFTs.value = data.result;
    //   console.log("hello");
    //   console.log(allNFTs.value);
    //   loadings.value = false;
    // }

    // async function fetchNftCollections() {
    //   try {
    //     const response = await axios.get(
    //       "http://127.0.0.1:8000/collections/getcollection/"
    //     );
    //     nftCollections.value = response.data;
    //     console.log(nftCollections.value);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    return {
      allNFTs,
      testNFT,
      loadings,
      nftSongs,
      loading,
      truncatedName,
      navigateToExplore,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.rowContainer {
  position: relative;
  height: 450px;
}
.recentSection {
  /* background: #3c3c3c; */
  border-radius: 0px;
  height: auto;
  border: none;
  /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.2); */
  /* outline: none; */
  /* background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  ); */
  /* backdrop-filter: blur(20px); */
  max-width: 1700px;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* 100% of the viewport height */
  width: 100%;
}

.titleText {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitleText {
  font-size: 18px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}
.horizontalScroll {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  overflow-x: scroll;
  height: auto;
  width: 100%;
}
.horizontalScroll:not(:hover)::-webkit-scrollbar {
  display: none;
}
.horizontalScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #333;
}

.horizontalScroll::-webkit-scrollbar-track {
  background-color: #222;
}

.horizontalScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #4d4d4d;
}

.horizontalScroll::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

/* .seeButton {
  position: absolute;
  top: 0;
  right: 20px;
  border-radius: 25px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  background-color: #0d0d0d00;
} */

.seeButtonBottom {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .glassmorphism {
    height: 460px;
  }
  .rowContainer {
    height: 330px;
  }

  .titleText {
    font-size: 25px;
  }
}
</style>
