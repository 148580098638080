<template>
        <input
        placeholder="search artist, music...."
        class="searchInput py-0 px-2 text-white"
      />

  </template>
  
  <script>
  export default {
  name: "SearchBar",
  setup() {},
}
  </script>
  
  <style scoped>
 .searchInput {
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  width: 100%;
  height: 40px;
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
} 
  
 
  </style>
  