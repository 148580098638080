<template>
  <v-card max-height="420" max-width="250" class="playcard elevation">
    <img class="songImage" src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/coming1.jfif" alt="logo" />
    <div class="pl-4 pr-1 pb-3" style="width: 100%">
      <div class="d-flex my-2" style="gap: 10px">
        <img
          class="artistImage"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
          alt="logo"
        />
        <p class="text-white subText" style="opacity: 0.6">Gummint</p>
      </div>
      <p class="text-white titleText blur-text" style="opacity: 0.3">*******</p>
      <p class="text-white subText blur-text" style="opacity: 0.3">
        Streaming Royalty: **
      </p>
    </div>
    <div class="mx-auto mb-5 text-center" style="width: 100%">
      <slot name="button">
        <v-btn class="btn px-4 text-center"
          >20
         </v-btn>
      </slot>
    </div>
    <v-overlay
      v-model="overlay"
      contained
      class="align-center justify-center backgroundBlur"
    >
      <div class="text-center mx-auto">
        <v-icon class="text-white text-center mx-auto" size="30">mdi-lock-outline</v-icon>
        <h1 class="text-white text-center px-5">Coming Soon</h1>
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { ref } from "vue";
// import router from "../router";
// import { useRoute } from "vue-router";

export default {
  name: "NFTCard",
  // props: {
  //   nftData: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  setup() {
    const overlay = ref(true);
    // console.log(props.nftData)
    // const route = useRoute();
    // const data = ref(props.nftData);
    // let imageUrl = "http://127.0.01:8000/" + props.nftData.image;
    // let artistImage = "http://127.0.01:8000/" + props.nftData.collection.artist.profilepicture;
    // console.log(imageUrl)
    // console.log(artistImage)
    // const image = ref(imageUrl);
    // const artistProfilePicture = ref(artistImage);
    // const maxLength = 16;
    // // const truncatedText = computed(function () {
    // //   return metadata.value.nftDescription.length > maxLength
    // //     ? metadata.value.nftDescription.substring(0, maxLength) + "..."
    // //     : metadata.value.nftDescription;
    // // });

    // const navigateToUser = (id) => {
    //   console.log(id)
    //   router.push({ path: `/collection/${route.params.id}/${id}` });
    // };

    // console.log(JSON.parse(nftdata.value));

    return {
      // data,
      overlay,

      // image,
      // artistProfilePicture
      // truncatedText,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.backgroundBlur {
  backdrop-filter: blur(10px); /* Adjust the blur value */
}

.blur-text {
  color: transparent;
  opacity: 0.4;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adjust the values for the blur effect */
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}
.playcard {
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 220px;
  width: 100%;
  object-fit: cover;
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.gradientColor {
  color: #57f2cc;
}
.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: 5px;
  /* box-shadow: 0 0 2px #eee; */
  border-radius: 20px;
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.playbutton {
  font-size: 60px;
}

.artistImage {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    height: 310px;
  }

  .songImage {
    height: 160px;
    width: 100%;
  }

  .subText {
    font-size: 12px;
  }

  .titleText {
    font-size: 16px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}

/* .title {
  font-size: 30px;
} */
</style>
