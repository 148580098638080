<template>
    <div class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
  </template>
  
  <script>
  export default {
    name: "LoadingSpinner",
  };
  </script>
  
  <style scoped>
  .mainProgressCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  </style>