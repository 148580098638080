<template>
  <div>
    <div v-if="loading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-card max-width="1200" class="mx-auto" flat color="transparent">
        <v-tabs centered center-active color="white" v-model="tab">
          <v-tab class="tabStyle">Latest Release</v-tab>
          <!-- <v-tab class="tabStyle">Hip Hop</v-tab>
        <v-tab class="tabStyle">House</v-tab>
        <v-tab class="tabStyle">Amapiano</v-tab>
        <v-tab class="tabStyle">RnB</v-tab>
        <v-tab class="tabStyle">Pop</v-tab>
        <v-tab class="tabStyle">Rock</v-tab>
        <v-tab class="tabStyle">Afro</v-tab>
        <v-tab class="tabStyle">Other</v-tab> -->
        </v-tabs>
      </v-card>
      <v-card max-width="1500" class="mx-auto" flat color="transparent">
        <v-tabs-items v-model="tab" class="mt-4">
          <v-tab-item>
            <div v-if="!loading" class="nftcollection-grid mt-4">
              <div v-for="collection in filteredCollections" :key="collection">
                <CollectionCard class="my-1" :collectionData="collection" />
              </div>
            </div>
            <div
              v-if="!loading && filteredCollections.length == 0"
              class="nftcollection-grid mt-4"
            >
              <div v-for="i in 5" :key="i">
                <NFTComingSoon class="my-1" />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import NFTCard from "./NFTCard.vue";
import CollectionCard from "./CollectionCard.vue";
import NFTComingSoon from "./NFTComingSoon.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

export default {
  name: "ExploreTab",
  components: {
    NFTCard,
    CollectionCard,
    NFTComingSoon,
  },
  setup() {
    const tab = ref(null);
    const nftCollections = ref([]);
    const allCollection = ref(null);
    const loadings = ref(true);

    const categories = ref([
      "Trending",
      "Hip Hop",
      "House",
      "Amapiano",
      "RnB",
      "Pop",
      "Rock",
      "Afro",
      "Other",
    ]);

    onMounted(() => {
      refetch();
    });

    const { result, loading, error, onResult, refetch } = useQuery(
      gql`
        query allCollection {
          allCollection {
            id
            name
            about
            image
            genre
          }
        }
      `
    );

    watch(result, (value) => {
      nftCollections.value = value.allCollection;
    });

    //   //After adding your Hardhat network to your metamask, this code will get providers and signers
    //   // const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   // const signer = provider.getSigner();
    //   // //Pull the deployed contract instance
    //   // let market = JSON.parse(Marketplace);
    //   // let contract = new ethers.Contract(market.address, market.abi, signer);
    //   // //create an NFT Token
    //   // let transaction = await contract.getAllNFTs();
    //   // console.log(transaction);
    //   // //Fetch all the details of every NFT from the contract and display
    //   // const items = await Promise.all(
    //   //   transaction.map(async (i) => {
    //   //     const tokenURI = await contract.tokenURI(i.tokenId);
    //   //     console.log(tokenURI);
    //   //     let meta = await axios.get(tokenURI, {
    //   //       headers: {
    //   //         Accept: "text/plain",
    //   //       },
    //   //     });
    //   //     meta = meta.data;

    //   //     let price = ethers.utils.formatUnits(i.price.toString(), "ether");
    //   //     let item = {
    //   //       price,
    //   //       tokenId: i.tokenId.toNumber(),
    //   //       seller: i.seller,
    //   //       owner: i.owner,
    //   //       image: meta.image,
    //   //       nftName: meta.nftName,
    //   //       nftDescription: meta.nftDescription,
    //   //     };
    //   //     return item;
    //   //   })
    //   // );
    //   // testNFT.value = items[1];
    //   // allNFTs.value = items;
    //   // loadings.value = false;
    //   let NFTcontractData = JSON.parse(CreateNft);

    //   let address = NFTcontractData.address;

    //   const chain = EvmChain.MUMBAI;

    //   const response = await Moralis.EvmApi.nft.getContractNFTs({
    //     address,
    //     chain,
    //   });
    //   // console.log(response.toJSON());
    //   var data = response.toJSON();
    //   // testNFT.value = items[1];
    //   allNFTs.value = data.result;
    //   console.log("hello");
    //   console.log(allNFTs.value);
    //   loadings.value = false;
    // }

    //create an axios get request to fetch all the NFTs
    // async function fetchNftCollections() {
    //   try {
    //     const response = await axios.get(
    //       "http://127.0.0.1:8000/collections/getcollection/"
    //     );
    //     nftCollections.value = response.data;
    //     console.log(nftCollections.value);
    //     console.log(nftCollections);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    // Get filtered collections based on the selected category
    const filteredCollections = computed(() => {
      if (!nftCollections.value || nftCollections.value.length === 0) {
        return [];
      }

      const selectedCategory = categories.value[tab.value];
      return nftCollections.value.filter((collection) => {
        // Assuming you have a category field in the collection object
        if (selectedCategory == "Trending") {
          return collection;
        } else {
          return collection.genre == selectedCategory;
        }
      });
      // if (!result || result.length === 0) {
      //   return [];
      // }

      // const selectedCategory = categories.value[tab.value];
      // return result.filter((collection) => {
      //   // Assuming you have a category field in the collection object
      //   if (selectedCategory == "Trending") {
      //     return collection;
      //   } else {
      //     return collection.genre == selectedCategory;
      //   }
      // });
    });

    return {
      tab,
      loading,
      nftCollections,
      categories,
      filteredCollections,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 100%;
  /* margin-inline: auto; */
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* 100% of the viewport height */
}


@media screen and (min-width: 370px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.3rem;
    padding-left: 10px;
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.tabStyle {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: white;
}


</style>
