<template>
    <v-dialog v-model="dialogModel" width="300" height="400">
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto">
          <v-card-title class="text-white text-center mx-auto text-h4"
            >Following</v-card-title
          >
          <v-divider class="mb-5"></v-divider>
          <div v-if="followers && followers.length > 0">
            <div v-for="follower in followers" :key="follower.id">
              <router-link
                :to="`/artist/${follower.artist.id}`"
                class="custom-link"
              >
                <div class="d-flex mb-2">
                  <img
                    class="followerImage"
                    :src="mediaUrl + follower.artist.user.profilePicture"
                    alt="Follower Profile Picture"
                  />
                  <p class="titleText ml-4">{{ follower.artist.name }}</p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <p class="text-center">Not following any artists</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    name: 'FollowersDialog',
    props: {
      modelValue: Boolean,
      followers: Array,
      mediaUrl: String,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const dialogModel = ref(props.modelValue);
  
      watch(() => props.modelValue, (newValue) => {
        dialogModel.value = newValue;
      });
  
      watch(dialogModel, (newValue) => {
        emit('update:modelValue', newValue);
      });
  
      return {
        dialogModel,
      };
    },
  };
  </script>
  
  <style scoped>
  .glassmorphismDialogue {
    background: #0d0d0d;
    border-radius: 30px;
    background: linear-gradient(
      108.46deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(80px);
    min-height: 300px;
    width: 300px;
  }
  
  .followerImage {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
  }
  
  .custom-link {
    text-decoration: none;
    color: inherit;
  }
  
  .titleText {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    color: white;
  }
  
  @media screen and (max-width: 600px) {
    .glassmorphismDialogue {
      width: 95%;
    }
  }
  </style>