<template>
  <v-main>
    <div class="backgroundGradient"></div>

    <div class="d-flex justify-center align-center loginContainer">
      <v-card
        class="rounded-xl loginCard elevation-15 text-center mx-3"
        max-width="600"
      >
        <div class="mx-auto text-center">
          <v-img
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
            class="mx-auto"
            alt="logo"
            height="200"
            width="200"
            contain
          ></v-img>
        </div>
        <v-card-title class="text-white text-center titleMargin"
          >Welcome to Gummint</v-card-title
        >
        <p class="modalSubtitleText text-center">
          Fill in the information to register an account
        </p>

        <v-card-text>
          <v-text-field
            class="text-white"
            v-model="loginEmail"
            variant="underlined"
            :clearable="true"
            prepend-icon="mdi-email-outline"
            label="Email"
            type="email"
            required
          ></v-text-field>
          <v-text-field
            class="text-white"
            v-model="userName"
            variant="underlined"
            :clearable="true"
            prepend-icon="mdi-account"
            label="User Name"
            required
          ></v-text-field>
          <v-text-field
            class="text-white"
            v-model="password"
            variant="underlined"
            :clearable="true"
            prepend-icon="mdi-lock-outline"
            label="Password"
            type="password"
            required
          ></v-text-field>

          <!-- Checkbox for terms and conditions -->
          <v-row class="d-flex align-center">
            <v-col cols="auto">
              <v-checkbox
                v-model="termsChecked"
                class="text-white"
                label=""
                required
              ></v-checkbox>
            </v-col>
            <v-col cols="auto pb-9">
              <h4 class="text-white">
                I agree to the<router-link class="text-blue" to="/terms">
                  terms and conditions</router-link
                >
              </h4>
            </v-col>
          </v-row>

          <router-link to="/authentication/register">
            <h3 class="my-4 text-white">
              Already have an account? <span class="text-blue">Login here</span>
            </h3>
          </router-link>
          <div class="text-center">
            <v-btn class="mx-auto btnSubmit mb-3" size="x-large" @click="login">
              <v-icon start icon="mdi-arrow-right"></v-icon>Register
              <v-progress-circular
                v-if="signupLoading"
                indeterminate
                color="white"
                :size="30"
              ></v-progress-circular>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { Magic } from "magic-sdk";
import { useAuthenticationStore } from "../../stores/authentication";
import { storeToRefs } from "pinia";
import SearchBar from "../../components/SearchBar.vue";
import { useRoute } from "vue-router";
import router from "../../router";
import { OAuthExtension } from "@magic-ext/oauth";

export default {
  name: "RegisterPage",

  components: {
    SearchBar,
  },

  setup() {
    const loading = ref(false);
    const submitLoading = ref(false);
    const artistName = ref("");
    const useraccounts = null;
    const termsChecked = ref(false);
    // user athentication store state and methods
    const userName = ref("");
    const password = ref("");
    const userAuth = useAuthenticationStore();
    const loginEmail = ref("");
    const { email } = storeToRefs(userAuth);
    const { accounts } = storeToRefs(userAuth);
    const { isAuthenticated } = storeToRefs(userAuth);
    const { setUserDetailsFromWallet } = userAuth;
    const { setbackendDetails } = userAuth;
    const { setMagicAuthToken } = userAuth;
    const { clearCookies } = userAuth;
    const showLoginModal = ref(false);
    const signupLoading = ref(false);
    const route = useRoute();

    const customNodeOptions = {
      chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
      displayName: "polygon Mainnet",
      blockExplorer: "https://polygonscan.com",
      ticker: "MATIC",
      tickerName: "Polygon",
      rpcTarget: process.env.VUE_APP_ALCHEMY_API_URL,
    };

    const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
      network: customNodeOptions,
      extensions: [new OAuthExtension()],
    });

    onMounted(() => {
      console.log(email.value);
      console.log(accounts.value);
      console.log(isAuthenticated.value);
      // getRedirectData();
    });

    const navigateToHome = () => {
      // Only navigate if the current route is not the home page
      if (route.name !== "Home") {
        router.push({ name: "Home" }); // Navigate to the Home route
      }
    };

    const createAccount = async (user_walletaccount) => {
      const formData = new FormData();
      formData.append("username", userName.value);
      formData.append("user_type", "Fan");
      formData.append("email", loginEmail.value);
      formData.append("public_key", user_walletaccount);
      formData.append("password", password.value);
      formData.append("password2", password.value);

      await axios
        .post(
          process.env.VUE_APP_BACKEND_API_URL + "authenticate/register",
          formData
        )
        .then((response) => {
          console.log(response.data);
          let _authToken = response.data.body.token;
          let _userId = response.data.body.id;
          let _username = response.data.body.username;
          let _userType = response.data.body.user_type;
          let _userTypeId = response.data.body.type_id;
          setbackendDetails(
            _username,
            _userId,
            _authToken,
            _userType,
            _userTypeId
          );
          navigateToHome();
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
          logout();
        });
    };

    const login = async () => {
      if (
        !loginEmail.value ||
        !userName.value ||
        !password.value ||
        !termsChecked.value
      ) {
        // Display an error message or take appropriate action
        alert(
          "Please fill in all required fields and agree to the terms and conditions."
        );
        return;
      }

      if (password.value.length < 8) {
        // Display an error message or take appropriate action
        alert("Please enter a password with at least 8 characters");
        return;
      }
      try {
        signupLoading.value = true;
        if (loginEmail.value) {
          const handle = await magic.auth.loginWithEmailOTP({
            email: loginEmail.value,
          });

          if (handle) {
            const { email, publicAddress } = await magic.user.getMetadata();
            if (email && publicAddress) {
              setUserDetailsFromWallet(email.email, publicAddress);
            }
            setMagicAuthToken(handle);
            let loginResults = await createAccount(publicAddress);
            console.log(loginResults);
          } else {
            console.error("Registration failed");
          }
          console.log(handle);
        }
      } catch (error) {
        console.log(error.message);
        alert("Registration failed, please try again.");
        await logout();
        signupLoading.value = false;
      } finally {
        signupLoading.value = false;
      }
    };

    const logout = async () => {
      try {
        await magic.user.logout();
        console.log(await magic.user.isLoggedIn()); // => `false`
        clearCookies();
      } catch {
        // Handle errors if required!
      }
    };

    return {
      login,
      logout,
      useraccounts,
      loading,
      artistName,
      submitLoading,
      isAuthenticated,
      navigateToHome,
      showLoginModal,
      signupLoading,
      loginEmail,
      password,
      userName,
      termsChecked,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.round {
  border-radius: 60px;
}

.loginContainer {
  width: 100%;
}

.list-item-text {
  color: white; /* Change to your desired text color */
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.backgroundGradient {
  position: absolute;
  width: 100%;
  height: 700px;
  top: -100px;
  background: url("https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/profileGradient.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.list-item-title {
  font-size: 16px; /* Adjust the font size as needed */
}

/* Custom styling for the router link */
.router-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from parent */
  cursor: pointer; /* Show pointer cursor on hover */
}
.drawer-container {
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1200;
  overflow: hidden;
}

.non-scrollable-drawer {
  height: 100%;
  overflow-y: auto;
}
.v-overlay--active {
  backdrop-filter: blur(50px);
  z-index: 9999;
}
.titleText {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.titleMargin {
  margin-top: -50px;
}
.loginCard {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
  width: 500px;
}

.subtitleText {
  font-size: 25px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.modalSubtitleText {
  font-size: 15px;
  max-width: 450px;
  margin: auto;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.navigationDrawer {
  height: 10px;
}

.searchInput {
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  width: 500px;
  height: 40px;
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

input::placeholder {
  color: #ffffff;
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
}

.btn {
  padding: 8px;
  text-align: center;
  transition: 0.5s;
  font-family: "Poppins", sans-serif;
  background-size: 200% auto;
  color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 120px;
}

.loginBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  /* background-image: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%); */
}
.btn-3 {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
}

.btnRequest {
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

.btnSubmit {
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  /* width: 240px; */
  /* font-size: 20px; */
  font-family: "Poppins", sans-serif;
  text-align: center;
  /* width: 180px; */
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  /* padding-bottom: 10px; */
  /* height: 40px; */
  /* background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%); */
}

@media screen and (max-width: 600px) {
  .searchInput {
    width: 75%;
    font-size: 12px;
  }

  .spaceContainer {
    display: none;
  }

  .logoContainer {
    display: none;
  }
}
</style>
