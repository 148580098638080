<template>
  <v-main>
    <LoadingSpinner v-if="isMainLoading" />
    <div v-else class="contentContainer">
      <BackgroundCover :coverPicture="coverPicture" />
      <UserProfile
        :profilePicture="profilePicture"
        :isAccountOwner="isAccountOwner"
        :isAuthenticated="isAuthenticated"
        @openSettings="showSettings = true"
        :fanData="fanData"
        :followersCount="followersResult.length"
        :nftCount="nftCount"
        @showFollowers="showFollowersPopup = true"
      />

      <v-card max-width="1200" flat color="transparent" class="mt-7">
        <v-tabs
          v-model="tab"
          centered
          center-active
          color="rgb(255, 123, 0)"
          style="font-size: 22px"
        >
          <v-tab value="nfts" class="tabStyle">Owned NFTs</v-tab>
          <v-tab value="account" class="tabStyle">Account</v-tab>
        </v-tabs>
      </v-card>

      <v-card max-width="1500" class="mx-auto" flat color="transparent">
        <v-window v-model="tab">
          <v-window-item value="nfts">
            <OwnedNFTs :allNFTs="allNFTs" @explore="navigateToExplore" />
          </v-window-item>
          <v-window-item value="account">
            <AccountInfo
              :email="email"
              :isAccountOwner="isAccountOwner"
              :isAuthenticated="isAuthenticated"
              :web3Enabled="web3Enabled"
              :userId="userId"
              :ownedNFTs="allNFTs"
              @showWallet="showWallet"
            />
          </v-window-item>
        </v-window>
      </v-card>
    </div>

    <EditProfileDialog
      v-model="showEditDialog"
      :coverPicture="coverPicture"
      :profilePicture="profilePicture"
      @updateProfile="updateProfile"
      @handleCoverFileChange="handleCoverFileChange"
      @handleProfileFileChange="handleProfileFileChange"
      :updateLoading="updateLoading"
    />

    <SettingsDialog
      v-model="showSettings"
      :web3Enabled="web3Enabled"
      @editProfile="showEditDialog = true"
      @editBanking="showBankingDialog = true"
      @toggleWeb3="handleCheckboxChange"
    />

    <BankingDetailsDialog
      v-model="showBankingDialog"
      :bankDetails="bankDetails"
      :southAfricanBankCodes="southAfricanBankCodes"
      :accountTypes="accountTypes"
      @updateBankingDetails="updateBankingDetails"
      :updateBankLoading="updateBankLoading"
    />

    <FollowersDialog
      v-model="showFollowersPopup"
      :followers="followersResult"
      :mediaUrl="mediaUrl"
    />

    <BottomFooter class="footer" />
  </v-main>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { Magic } from "magic-sdk";
import axios from "axios";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Network, Alchemy } from "alchemy-sdk";
import { ethers } from "ethers";
import AuthManager from "../authentication/authentication";
import Marketplace from "../Marketplace.json";
import CreateNft from "../CreateNft.json";

import LoadingSpinner from "../components/LoadingSpinner.vue";
import BackgroundCover from "../components/BackgroundCover.vue";
import UserProfile from "../components/UserProfile.vue";
import OwnedNFTs from "../components/OwnedNFTs.vue";
import AccountInfo from "../components/AccountInfo.vue";
import EditProfileDialog from "../components/EditProfileDialog.vue";
import SettingsDialog from "../components/SettingsDialog.vue";
import BankingDetailsDialog from "../components/BankingDetailsDialog.vue";
import FollowersDialog from "../components/FollowersDialog.vue";
import BottomFooter from "../components/BottomFooter.vue";

export default {
  name: "AccountPage",

  components: {
    LoadingSpinner,
    BackgroundCover,
    UserProfile,
    OwnedNFTs,
    AccountInfo,
    EditProfileDialog,
    SettingsDialog,
    BankingDetailsDialog,
    FollowersDialog,
    BottomFooter,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const accountUserId = route.params.id;
    const mainLoading = ref(true);

    // user athentication store state and methods
    const authManager = new AuthManager();
    const userAuth = useAuthenticationStore();
    const { email } = storeToRefs(userAuth);
    const { username } = storeToRefs(userAuth);
    const { accounts } = storeToRefs(userAuth);
    const { isAuthenticated } = storeToRefs(userAuth);
    const { userAuthToken } = storeToRefs(userAuth);
    const { userId } = storeToRefs(userAuth);
    const { isWeb3Enabled } = storeToRefs(userAuth);
    const { userName } = storeToRefs(userAuth);
    const { setWeb3Enabled } = userAuth;
    const web3Enabled = computed(() => isWeb3Enabled.value);

    const handleCheckboxChange = (event) => {
      console.log(isWeb3Enabled);
      const newValue = event.target.checked;
      console.log("new value: " + newValue); // Output the new value of the checkbox
      setWeb3Enabled(newValue);
      console.log("web3: " + web3Enabled.value);
      console.log("isweb3: " + isWeb3Enabled.value);
    };

    const followersResult = ref([]);
    const isFollower = ref(false);

    const showEditDialog = ref(false);
    const showSettings = ref(false);
    const showBankingDialog = ref(false);
    const profilePicture = ref(null);
    const coverPicture = ref(null);
    const profilePlaceholder = ref(null);
    const coverPlaceholder = ref(null);
    const updateLoading = ref(false);
    const showFollowersPopup = ref(false);
    const nftCount = ref(0);
    const tab = ref(null);

    const bankDetails = ref({});
    const bankAccountName = ref("");
    const bankAccountNumber = ref("");
    const selectedBank = ref(null);
    const selectedAccountType = ref(null);
    const southAfricanBankCodes = ref([
      "ABSA",
      "FNB",
      "Nedbank" /* Add more bank codes */,
      "Capitec",
      "Standard Bank",
    ]);

    const accountTypes = ref([
      "Savings Account",
      "Checking Account",
      "Business Account",
      // Add more account types as needed
    ]);

    const updateBankLoading = ref(false);

    const selectedProfileImage = ref(null);
    const selectedCoverImage = ref(null);

    const fanData = ref(null);

    const mediaUrl = process.env.VUE_APP_MEDIA_API_URL;

    const allNFTs = ref([]);
    let NFTcontractData = JSON.parse(CreateNft);
    let address = NFTcontractData.address;

    onMounted(() => {
      window.scrollTo(0, 0);
      getMyNFTs();
      getManuallyOwnedNFTs();
      refetch();
      refetchFollowers();
    });

    const settings = {
      apiKey: process.env.VUE_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
      network: Network.MATIC_MAINNET, // Replace with your network.
    };

    const alchemy = new Alchemy(settings);
    const { result, loading, error, refetch } = useQuery(
      gql`
        query fansByUserid($userId: Int!) {
          fansByUserid(userId: $userId) {
            id
            user {
              username
              publicKey
              profilePicture
              profileBackground
              bankName
              bankAccount
              bankAccountType
              bankAccountHolder
              dateJoined
            }
          }
        }
      `,
      {
        userId: Number(userId.value),
      }
    );

    watch(result, (responeData) => {
      let value = responeData.fansByUserid; //
      if (value.length > 0) {
        fanData.value = value[0].user;
        if (value[0].user.profileBackground) {
          coverPicture.value =
            process.env.VUE_APP_MEDIA_API_URL + value[0].user.profileBackground;
        }
        if (value[0].user.profilePicture) {
          profilePicture.value =
            process.env.VUE_APP_MEDIA_API_URL + value[0].user.profilePicture;
        }
        bankAccountName.value = value[0].user.bankAccountHolder;
        bankAccountNumber.value = value[0].user.bankAccount;
        selectedBank.value = value[0].user.bankName;
        selectedAccountType.value = value[0].user.bankAccountType;
      }
    });

    const {
      result: follower,
      loading: followsLoading,
      refetch: refetchFollowers,
    } = useQuery(
      gql`
        query fanSubs($id: Int!) {
          fanSubs(id: $id) {
            id
            artist {
              id
              user {
                profilePicture
                profileBackground
              }
              name
            }
          }
        }
      `,
      {
        id: Number(userId.value),
      }
    );

    watch(follower, (value) => {
      followersResult.value = value.fanSubs;
      const isUserFollower = followersResult.value.some(
        (sub) => sub.user.id === userId.value
      );
      isFollower.value = isUserFollower;
    });

    const isAccountOwner = computed(() => {
      if (accounts.value == accountUserId) {
        return true;
      } else {
        return false;
      }
    });

    const handleCoverFileChange = (event) => {
      selectedCoverImage.value = event.target.files[0];
      coverPicture.value = URL.createObjectURL(selectedCoverImage.value);
      coverPlaceholder.value = URL.createObjectURL(selectedCoverImage.value);
    };

    const handleProfileFileChange = (event) => {
      selectedProfileImage.value = event.target.files[0];
      profilePicture.value = URL.createObjectURL(selectedProfileImage.value);
      profilePlaceholder.value = URL.createObjectURL(
        selectedProfileImage.value
      );
    };

    const showWallet = async () => {
      const customNodeOptions = {
        rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
        chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
      };

      // const customNodeOptions = {
      //   rpcUrl:
      //     "https://polygon-rpc.com/", // Polygon RPC URL
      //   chainId: 137, // Polygon chain id
      // };
      const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
        network: customNodeOptions,
      });
      // const web3 = new Web3(magic.rpcProvider);
      await magic.wallet.showUI();
    };

    const formatNftDataForBatchCall = (nftData) => {
      let NFTcontractData = JSON.parse(CreateNft);
      let nftcontractaddress = NFTcontractData.address;

      const formattedData = [];

      for (const listing of nftData) {
        formattedData.push({
          contractAddress: nftcontractaddress,
          tokenId: listing[0].toString(),
          tokenType: "ERC721",
        });
      }

      return formattedData;
    };

    const getMyNFTs = async () => {
      mainLoading.value = true;
      try {
        const provider = new ethers.JsonRpcProvider(
          process.env.VUE_APP_ALCHEMY_API_URL
        );
        // const signer = await provider.getSigner();
        // const signerAddress = signer.address;
        let createNft = JSON.parse(CreateNft);

        let market = JSON.parse(Marketplace);
        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );

        let nftContract = new ethers.Contract(
          createNft.address,
          createNft.abi,
          provider
        );
        let myNFTs = await contract.getListingsForSeller(accountUserId);

        nftCount.value = myNFTs.length;
        let allNFTsMetadataArray = [];
        // Iterate through each owned NFT and fetch its metadata
        for (let i = 0; i < myNFTs.length; i++) {
          let tokenId = myNFTs[i].tokenId;
          // Call tokenURI function to get the URI of the metadata
          const tokenURI = await nftContract.tokenURI(tokenId);
          // Fetch metadata using HTTP request
          const response = await fetch(tokenURI);
          const nftmetadata = await response.json();
          nftmetadata.tokenId = tokenId;
          allNFTsMetadataArray.push(nftmetadata);
        }

        allNFTs.value = allNFTsMetadataArray;
        // allNFTs.value = allNFTs.value.concat(metadataResponse);
        mainLoading.value = false;
      } catch (e) {
        mainLoading.value = false;
        console.error(e);
      }
    };

    const getMyNFTsMagicWallet = async () => {
      mainLoading.value = true;
      try {
        const customNodeOptions = {
          rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
          chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
        };

        const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
          network: customNodeOptions,
        });

        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();
        const signerAddress = signer.address;
        let market = JSON.parse(Marketplace);
        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );
        let myNFTS = await contract.getListingsForSeller(signerAddress);
        nftCount.value = myNFTS.length;
        const formattedData = formatNftDataForBatchCall(myNFTS);

        const metadataResponse = await alchemy.nft.getNftMetadataBatch(
          formattedData,
          {
            tokenUriTimeoutInMs: 5000,
            refreshCache: true,
          }
        );

        allNFTs.value = allNFTs.value.concat(metadataResponse);
        mainLoading.value = false;
      } catch (e) {
        mainLoading.value = false;
        console.error(e);
      }
    };

    const getManuallyOwnedNFTs = async () => {
      mainLoading.value = true;
      try {
        let provider;
        let signer;
        let signerAddress;

        const authMethod = await authManager.getCurrentAuthenticationMethod();

        if (authMethod === "magicwallet") {
          provider = new ethers.BrowserProvider(authManager.magic.rpcProvider);
          signer = await provider.getSigner();
          signerAddress = signer.address;
        } else if (authMethod === "web3modal") {
          const { getSigner } = useWeb3ModalAccount();
          signer = getSigner();
          signerAddress = await signer.getAddress();
          provider = signer.provider;
        } else {
          throw new Error("User not authenticated");
        }

        let gummintNFTJSON = JSON.parse(CreateNft);
        let contract = new ethers.Contract(
          gummintNFTJSON.address,
          gummintNFTJSON.abi,
          provider
        );

        let options = {
          contractAddresses: [gummintNFTJSON.address],
        };

        let response = await alchemy.nft.getNftsForOwner(
          signerAddress,
          options
        );
        const uniqueNFTIds = new Set(allNFTs.value.map((nft) => nft.tokenId));

        // Filter out duplicates and add new unique NFTs
        const newUniqueNFTs = response.ownedNfts.filter((nft) => {
          if (!uniqueNFTIds.has(nft.tokenId)) {
            uniqueNFTIds.add(nft.tokenId);
            return true;
          }
          return false;
        });

        // Concatenate only the new unique NFTs
        allNFTs.value = allNFTs.value.concat(newUniqueNFTs);
        mainLoading.value = false;
      } catch (e) {
        mainLoading.value = false;
        console.error(e);
      }
    };

    const isMainLoading = computed(() => {
      if (userId.value) {
        if (loading.value || followsLoading.value || mainLoading.value) {
          return true;
        } else {
          return false;
        }
      } else {
        if (mainLoading.value) {
          return true;
        } else {
          return false;
        }
      }
    });

    const updateProfle = async () => {
      if (!updateLoading.value) {
        try {
          updateLoading.value = true;
          const formData = new FormData();
          if (selectedProfileImage.value) {
            formData.append("profile_picture", selectedProfileImage.value);
          }

          if (selectedCoverImage.value) {
            formData.append("profile_background", selectedCoverImage.value);
          }

          const response = await axios.patch(
            process.env.VUE_APP_BACKEND_API_URL + "fans/fanseditprofile/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${userAuthToken.value}`,
              },
            }
          );
          refetch();
          updateLoading.value = false;
          showEditDialog.value = false;
        } catch (error) {
          console.error(error);
          updateLoading.value = false;
          showEditDialog.value = false;
          alert("An error occurred. Please try again.");
        }
      }
    };

    const updateBankingDetails = async () => {
      if (updateBankLoading.value == false) {
        // Make a PATCH request using Axios
        const apiUrl =
          process.env.VUE_APP_BACKEND_API_URL + "fans/fanseditprofile/"; // Replace with your actual API endpoint
        const data = {
          bank: selectedBank,
          bank_account_type: selectedAccountType,
          bankaccount_holder: bankAccountName,
          bank_account: bankAccountNumber,
        };

        updateBankLoading.value = true;
        axios
          .patch(apiUrl, data, {
            headers: {
              Authorization: `Token ${userAuthToken.value}`,
            },
          })
          .then((response) => {
            // Handle successful response
          })
          .catch((error) => {
            // Handle error
            console.error("Update failed", error);
          })
          .finally(() => {
            updateBankLoading.value = false;
          });
      }
    };

    const followUser = async () => {
      if (!isAuthenticated.value) {
        router.push({ path: "/authentication/login" });
      } else {
        console.log(userAuthToken.value);
        await axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL +
              "fans/fan_subscribe/" +
              artistId,
            {},
            {
              headers: {
                Authorization: `Token ${userAuthToken.value}`,
              },
            }
          )
          .then((response) => {
            refetchFollowers();
            console.log("Followed user:", response.data);
          })
          .catch((error) => {
            console.error("Error following user:", error);
          });
      }
    };

    const navigateToExplore = () => {
      router.push({ name: "Explore" });
    };

    return {
      showWallet,
      allNFTs,
      email,
      accounts,
      username,
      isMainLoading,
      showEditDialog,
      profilePicture,
      coverPicture,
      handleCoverFileChange,
      handleProfileFileChange,
      profilePlaceholder,
      coverPlaceholder,
      updateLoading,
      updateProfle,
      isAccountOwner,
      isAuthenticated,
      followUser,
      followersResult,
      showFollowersPopup,
      mediaUrl,
      nftCount,
      fanData,
      navigateToExplore,
      showSettings,
      showBankingDialog,
      bankAccountName,
      bankAccountNumber,
      selectedBank,
      southAfricanBankCodes,
      accountTypes,
      selectedAccountType,
      updateBankingDetails,
      updateBankLoading,
      bankDetails,
      tab,
      web3Enabled,
      handleCheckboxChange,
      isWeb3Enabled,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.p {
  font-family: "Poppins", sans-serif;
}

.profileDetails {
  z-index: 2;
}

.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.glassCard {
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  padding: 20px;
  height: 130px;
  border-radius: 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(10px);
}

.custom-list-item-title {
  font-size: 18px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
}

.custom-list-item-subtitle {
  font-size: 15px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
}

.editDialog {
  width: 600px;
}

.transparent-list {
  background-color: transparent !important;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of the viewport height */
}

.followerText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.subtitleText {
  font-size: 18px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.seeButtonBottom {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

.bioContainer {
  padding-left: 25px;
}

.followerImage {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100%;
}

.bannerImg {
  width: 600px;
  height: 200px;
  object-fit: cover;
  /* display: block; */
  /* margin: 0 auto; */
}

.custom-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
  /* Optionally, you can remove other link styles like hover and focus effects */
}

.profileImg {
  margin-top: -80px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.editProfileImage {
  height: 150px;
  width: 150px;
  border-radius: 150px;
}
.contentContainer {
  /* position: relative; */
  z-index: 9;
  min-height: 120vh;
}

.backgroundCover {
  position: absolute;
  width: 100%;
  height: 340px;
  top: -100px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.centeredTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.emptyText {
  max-width: 400px;
  font-size: 25px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}
.infoFlexContainer {
  max-width: 750px;
}

.tabStyle {
  font-size: 25px;
  color: white;
}

.vertical-line {
  border-left: 1px solid #999; /* Adjust the color and thickness as needed */
  height: 200px; /* Adjust the height of the line as needed */
  margin-left: 70px; /* Adjust the margin as needed */
}
.walletContainer {
  border: 1px solid #999;
  border-radius: 10px;
  padding: 10px;
  max-width: 720px;
  position: relative;
  z-index: 1;
}
.walletInfo {
  max-width: 320px;
  font-size: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}

.glassmorphism {
  margin-top: 50px;
  /* background: #0d0d0d;
  border: none;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(25px); */
  height: auto;
  min-height: 700px;
  /* top: 60px; */
  /* padding-top: 60px; */
}

.glassmorphismDialogue {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
  min-height: 300px;
  width: 600px;
}

.nftSectionInfo {
  max-width: 550px;
  font-size: 15px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}

.footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  /* padding: 20px 0; */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  z-index: 999;
}

.detailsContainer {
  margin-top: 60px;
  text-align: center;
  z-index: 10;
}

.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 90%;
  margin-inline: auto;
}

@media screen and (min-width: 380px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.headingText {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  /* color: white; */
  color: rgb(255, 123, 0);
  z-index: 2;
}

.editIcon {
  font-size: 25px;
  margin-left: 35px;
  color: white;
}

.subText {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.glassEffect {
  margin-top: 50px;
  position: absolute;
  z-index: 0;
  background-color: #2f0050;
  filter: blur(50px);
  height: 750px;
  width: 120%;
  margin-left: -60px;
}

.songImage {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 15%;
}
.btn {
  padding: 8px;
  text-align: center;
  transition: 0.5s;
  font-family: "Poppins", sans-serif;
  background-size: 200% auto;
  color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.btn-3 {
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

@media screen and (max-width: 600px) {
  .titleText {
    font-size: 16px;
  }

  .bannerImg {
    width: 100%;
  }

  .headingText {
    font-size: 32px;
  }

  .widthContainer {
    width: 75%;
  }

  .glassEffect {
    width: 130%;
  }

  .editDialog {
    width: 95%;
  }

  .glassmorphismDialogue {
    width: 95%;
  }
  /* .glassCard {
   height: 100px;
  } */
}
</style>
