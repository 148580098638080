<template>
    <v-dialog
      v-model="dialogModel"
      max-width="600"
      class="editDialog mx-auto text-center"
    >
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto" style="width: 100%">
          <img
            v-if="coverPlaceholder || coverPicture"
            class="bannerImg"
            :src="coverPlaceholder ? coverPlaceholder : coverPicture"
            alt="Cover"
          />
          <img
            v-else
            class="bannerImg"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/cover.png"
            alt="Default Cover"
          />
          <div class="mx-auto text-center">
            <img
              v-if="profilePicture || profilePlaceholder"
              class="profileImg mx-auto text-center"
              :src="profilePlaceholder ? profilePlaceholder : profilePicture"
              alt="Profile"
            />
            <img
              v-else
              class="profileImg"
              src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/defaultProfile.svg"
              alt="Default Profile"
            />
          </div>
  
          <p class="text-h5 text-center mt-3">Edit profile</p>
          <div class="mx-4">
            <v-file-input
              label="Profile picture"
              variant="underlined"
              prepend-icon="mdi-camera"
              required
              @change="handleProfileFileChange"
            ></v-file-input>
            <v-file-input
              label="Background cover picture"
              variant="underlined"
              prepend-icon="mdi-camera"
              required
              @change="handleCoverFileChange"
            ></v-file-input>
            <div class="text-center">
              <v-btn class="mx-auto btn btn-3 mb-3" @click="updateProfile"
                >Update
                <v-progress-circular
                  v-if="updateLoading"
                  indeterminate
                  color="white"
                  :size="23"
                ></v-progress-circular>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    name: 'EditProfileDialog',
    props: {
      modelValue: Boolean,
      coverPicture: String,
      profilePicture: String,
      updateLoading: Boolean,
    },
    emits: ['update:modelValue', 'updateProfile', 'handleCoverFileChange', 'handleProfileFileChange'],
    setup(props, { emit }) {
      const dialogModel = ref(props.modelValue);
      const coverPlaceholder = ref(null);
      const profilePlaceholder = ref(null);
  
      watch(() => props.modelValue, (newValue) => {
        dialogModel.value = newValue;
      });
  
      watch(dialogModel, (newValue) => {
        emit('update:modelValue', newValue);
      });
  
      const handleCoverFileChange = (event) => {
        emit('handleCoverFileChange', event);
        if (event.target.files[0]) {
          coverPlaceholder.value = URL.createObjectURL(event.target.files[0]);
        }
      };
  
      const handleProfileFileChange = (event) => {
        emit('handleProfileFileChange', event);
        if (event.target.files[0]) {
          profilePlaceholder.value = URL.createObjectURL(event.target.files[0]);
        }
      };
  
      const updateProfile = () => {
        emit('updateProfile');
      };
  
      return {
        dialogModel,
        coverPlaceholder,
        profilePlaceholder,
        handleCoverFileChange,
        handleProfileFileChange,
        updateProfile,
      };
    },
  };
  </script>
  
  <style scoped>
  .editDialog {
    width: 600px;
  }
  
  .glassmorphismDialogue {
    background: #0d0d0d;
    border-radius: 30px;
    background: linear-gradient(
      108.46deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(80px);
    min-height: 300px;
    width: 600px;
  }
  
  .bannerImg {
    width: 600px;
    height: 200px;
    object-fit: cover;
  }
  
  .profileImg {
    margin-top: -80px;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    object-fit: cover;
  }
  
  .btn {
    padding: 8px;
    text-align: center;
    transition: 0.5s;
    font-family: "Poppins", sans-serif;
    background-size: 200% auto;
    color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
  
  .btn-3 {
    background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  }
  
  @media screen and (max-width: 600px) {
    .editDialog,
    .glassmorphismDialogue,
    .bannerImg {
      width: 95%;
    }
  }
  </style>