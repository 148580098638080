import { defineStore } from "pinia";
import Cookies from "js-cookie"; // Import Cookies from js-cookie

export const useAuthenticationStore = defineStore("userAuthentication", {
  state: () => ({
    email: Cookies.get("email") || "",
    accounts: Cookies.get("walletAddress") || "",
    userAuthToken: Cookies.get("userAuthtoken") || "",
    userId: Cookies.get("userId") || "",
    userTypeId: Cookies.get("userTypeId") || "",
    username: Cookies.get("username") || "",
    userType: Cookies.get("userType") || "",
    magicAuthToken: Cookies.get("magicAuthToken") || "",
    isAdmin: Cookies.get("isAdmin") || false,
    web3Enabled: Cookies.get("web3enabled") || false

  }),
  getters: {
    isAuthenticated(state) {
      return (
        state.accounts !== ""
        // state.email !== "" &&
        // state.userId !== "" &&
        // state.userAuthToken !== "" &&
        // state.magicAuthToken !== "" &&
        // state.username !== "" &&
        // state.userType !== "" &&
        // state.userTypeId
      );
    },

    isWeb3Enabled(state){
      return state.web3Enabled;
    }
  },
  actions: {
    setUserDetailsFromWallet(useremail, useraccount) {
      this.email = useremail;
      this.accounts = useraccount;
      Cookies.set("walletAddress", useraccount);
      Cookies.set("email", useremail);
    },

    setUserWalletAddress(walletAddress) {
      console.log("hello")
      console.log("address " + walletAddress );
      this.accounts = walletAddress;
      Cookies.set("walletAddress", walletAddress);
    },

    setIsAdmin(isAdmin) {
      this.isAdmin = isAdmin;
      Cookies.set("isAdmin", isAdmin);
    },

    setbackendDetails(username, userId, userToken, userType, userTypeId) {
      this.username = username;
      this.userId = userId;
      this.userAuthToken = userToken;
      this.userType = userType;
      this.userTypeId = userTypeId
      Cookies.set("userAuthtoken", userToken);
      Cookies.set("userId", userId);
      Cookies.set("username", username);
      Cookies.set("userType", userType);
      Cookies.set("userTypeId", userTypeId);
    },

    setMagicAuthToken(magicAuthToken) {
      this.magicAuthToken = magicAuthToken;
      Cookies.set("magicAuthToken", magicAuthToken);
    },

    setWeb3Enabled(web3Enabled) {
      console.log("setWeb3Enabled");
      console.log("value: " + web3Enabled);
      this.web3Enabled = web3Enabled;
      Cookies.set("web3enabled", web3Enabled); // Convert to string
    },

    clearCookies() {
      // Clear the cookies for the specific keys
      Cookies.remove("walletAddress");
      Cookies.remove("email");
      Cookies.remove("userAuthtoken");
      Cookies.remove("userId");
      Cookies.remove("username");
      Cookies.remove("magicAuthToken");
      Cookies.remove("userType");
      Cookies.remove("userTypeId");
      Cookies.remove("isAdmin");

      // Clear the corresponding state properties
      this.email = "";
      this.accounts = "";
      this.userAuthToken = "";
      this.userId = "";
      this.username = "";
      this.magicAuthToken = "";
      this.userType = "";
      this.userTypeId = "";
      this.isAdmin = "false";
    },
  },
});
