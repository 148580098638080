<template>
  <v-main>
    <div class="backgroundGradient"></div>

    <div class="d-flex justify-center align-center loginContainer">
      <v-card
        class="rounded-xl loginCard elevation-15 text-center mx-3"
        max-width="600"
      >
        <div class="mx-auto text-center">
          <v-img
            class="mx-auto"
            src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
            alt="logo"
            height="200"
            width="200"
            contain
          ></v-img>
        </div>
        <v-card-title class="text-white text-center titleMargin"
          >Artist application</v-card-title
        >
        <div v-if="!formSubmitted">
          <p class="modalSubtitleText text-center">
            To apply for a music digital asset drop with us please submit your artist
            information and our team will review your application
          </p>

          <v-card-text class="text-white">
            <v-text-field
              v-model="artistName"
              variant="underlined"
              label="Artist Name"
              :clearable="true"
              prepend-icon="mdi-account"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              variant="underlined"
              :clearable="true"
              prepend-icon="mdi-email-outline"
              label="Email"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              v-model="musicLink"
              :clearable="true"
              prepend-icon="mdi-link"
              variant="underlined"
              label="Link to Music Platform"
              required
            ></v-text-field>
            <v-text-field
              v-model="phoneNumber"
              prepend-icon="mdi-phone-outline"
              variant="underlined"
              :clearable="true"
              label="Phone Number"
            ></v-text-field>
            <v-checkbox
              v-model="signedToLabel"
              variant="underlined"
              label="Signed to a Label"
            ></v-checkbox>
            <div class="text-center">
              <v-btn
                size="large"
                class="mx-auto btnRequest px-15 rounded-lg mb-3"
                @click="submitForm"
                >Submit application
                <v-progress-circular
                  v-if="submitLoading"
                  indeterminate
                  color="white"
                  :size="30"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text>
            <p class="text-white">
              Application received, the Gummint Team will get back to you soon
            </p>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import SearchBar from "../../components/SearchBar.vue";
import { useRoute } from "vue-router";
import router from "../../router";

export default {
  name: "RegisterPage",

  components: {
    SearchBar,
  },

  setup() {
    const artistName = ref("");
    const email = ref("");
    const musicLink = ref("");
    const phoneNumber = ref("");
    const signedToLabel = ref(false);
    const submitLoading = ref(false);
    const formSubmitted = ref(false);
    const route = useRoute();

    const navigateToHome = () => {
      // Only navigate if the current route is not the home page
      if (route.name !== "Home") {
        router.push({ name: "Home" }); // Navigate to the Home route
      }
    };

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    // Validation function to check if all fields are populated
    const validateForm = () => {
      if (!artistName.value || !email.value || !musicLink.value) {
        // Check if any of the required fields are empty
        return false; // Return false if any required field is empty
      }
      return true; // All required fields are populated
    };

    const validateLink = (link) => {
      // Regular expression to check if the link starts with http:// or https://
      const linkRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      return linkRegex.test(link);
    };

    // Validate email format

    async function submitForm() {
      submitLoading.value = true;

      if (!validateForm()) {
        alert("Please fill in all the required fields.");
        submitLoading.value = false;
        return;
      }
      if (!validateEmail(email.value)) {
        alert("Please enter a valid email address.");
        submitLoading.value = false;
        return;
      }
      // Validate link format
      if (!validateLink(musicLink.value)) {
        alert("Please enter a valid link to the music platform.");
        submitLoading.value = false;
        return;
      }

      const formData = new FormData();
      formData.append("artistname", artistName.value);
      formData.append("email", email.value);
      formData.append("linkToMusicPlatform", musicLink.value);
      formData.append("phonenumber", phoneNumber.value);
      formData.append("signedtoalabel", signedToLabel.value);
      // Submit the form data using an HTTP request, e.g. using Axios:
      await axios
        .post(
          process.env.VUE_APP_BACKEND_API_URL + "artists/artistapllication/",
          formData
        )
        .then((response) => {
          console.log(response.data);

          formSubmitted.value = true;
          submitLoading.value = false;
        })
        .catch((error) => {
          alert("Error please try again");
          submitLoading.value = false;
          console.error(error);
        });
    }

    return {
      artistName,
      email,
      musicLink,
      phoneNumber,
      signedToLabel,
      submitForm,
      navigateToHome,
      submitLoading,
      formSubmitted,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.loginContainer {
  width: 100%;
}

.backgroundGradient {
  position: absolute;
  width: 100%;
  height: 700px;
  top: -100px;
  background: url("https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/profileGradient.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.titleMargin {
  margin-top: -50px;
}
.loginCard {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
  width: 500px;
}

.modalSubtitleText {
  font-size: 15px;
  max-width: 450px;
  margin: auto;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: white;
}

a {
  text-decoration: none;
}

input::placeholder {
  color: #ffffff;
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
}

.btnRequest {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  border-color: white;
  border-width: 1px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

@media screen and (max-width: 600px) {
  .searchInput {
    width: 75%;
    font-size: 12px;
  }

  .spaceContainer {
    display: none;
  }

  .logoContainer {
    display: none;
  }
}
</style>
