<template>
  <v-card height="450" max-width="450" class="playcard">
    <div class="text-center">
      <!-- <img class="songImage" src="/images/lue.png" alt="logo" /> -->

      <div class="mt-3 mx-4 playerColor"></div>

      <div class="d-flex flex-row justify-space-around align-center mt-4 mx-10">
        <v-icon small class="gradientColor">mdi-rewind</v-icon>
        <v-icon class="gradientColor playbutton">mdi-play-circle</v-icon>
        <v-icon class="gradientColor">mdi-fast-forward</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "NFTPlay",

  data: () => ({}),
};
</script>

<style scoped>
.playcard {
  background: #0d0d0d;
  border-radius: 32px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.gradientColor {
  color: #57f2cc;
}

.playbutton {
  font-size: 60px;
}

.songImage {
  max-height: 340px;
  width: 100%;
  object-fit: cover;
}

.playerColor {
  height: 4px;
  background: linear-gradient(90deg, #57f2cc -0.68%, #8743ff 100.7%);
}
</style>
