<!-- src/components/Dashboard.vue -->
<template>
  <v-row justify="center" class="mt-8">
    <v-col cols="12" md="6">
      <v-card class="glassmorphism text-white mb-6">
        <v-card-title class="text-h5 mb-2">Weekly Streaming Analytics</v-card-title>
        <v-card-text>
          <LineChart
            v-if="weeklyChartData"
            :data="weeklyChartData"
            :options="chartOptions"
            height="250"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="glassmorphism text-white mb-6">
        <v-card-title class="text-h5 mb-2">Monthly Streaming Analytics</v-card-title>
        <v-card-text>
          <LineChart
            v-if="monthlyChartData"
            :data="monthlyChartData"
            :options="chartOptions"
            height="250"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue";
import PredictionChart from "./PredictionChart.vue";
import { Line as LineChart } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale);

export default {
  components: {
    LineChart,
    PredictionChart,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    combinedStreamingStats: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const createChartData = (statsType) => {
      const labels = new Set();
      const spotifyData = {};
      const appleData = {};

      props.combinedStreamingStats.forEach(stat => {
        const data = JSON.parse(stat[statsType]);
        Object.keys(data).forEach(date => {
          labels.add(date);
          if (stat.platform.name === 'Spotify') {
            spotifyData[date] = (spotifyData[date] || 0) + data[date];
          } else if (stat.platform.name === 'Apple Music') {
            appleData[date] = (appleData[date] || 0) + data[date];
          }
        });
      });

      const sortedLabels = Array.from(labels).sort();

      return {
        labels: sortedLabels,
        datasets: [
          {
            label: "Spotify Streams",
            data: sortedLabels.map(date => spotifyData[date] || 0),
            borderColor: "#1DB954",
            backgroundColor: "rgba(29, 185, 84, 0.2)",
          },
          {
            label: "Apple Music Streams",
            data: sortedLabels.map(date => appleData[date] || 0),
            borderColor: "#FA243C",
            backgroundColor: "rgba(250, 36, 60, 0.2)",
          },
        ],
      };
    };

    const weeklyChartData = computed(() => createChartData('weeklyStats'));
    const monthlyChartData = computed(() => createChartData('monthlyStats'));

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of Streams",
          },
        },
        x: {
          title: {
            display: true,
            text: "Time Period",
          },
        },
      },
    };

    return {
      weeklyChartData,
      monthlyChartData,
      chartOptions,
    };
  },
};
</script>
<style scoped>
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}
</style>
