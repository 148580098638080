<template>
  <div class="mainContainer">
    <v-container>
      <h1 class="my-6 text-center">Gummint</h1>
      <v-row justify="center" class="mt-8">
        <v-img
        src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/forgotpassword.gif"
          max-width="250"
          max-height="250"
        ></v-img>
      </v-row>
      <div>
        <v-alert
          v-model="errorAlert"
          dense
          dismissible
          outlined
          type="error"
          class="mt-10 mx-auto"
          style="max-width: 500px"
        >
          {{ errorMessage }}
        </v-alert>
        <h2 class="text-center font-weight-light mt-8 mb-4">
          Forgot your password?
        </h2>
        <h4 class="text-center font-weight-light mt-4 mb-4">
          Enter your email address below so we can send you instructions
        </h4>
        <v-row justify="center" class="mt-4 px-4">
          <v-col cols="8">
            <v-text-field
              class="mx-8 rounded-lg"
              :clearable="true"
              prepend-icon="mdi-email-outline"
              type="email"
              label="Enter email"
              clearable
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn
            color="#F24296"
            @click=""
            rounded
            class="mt-4 mb-8"
            type="submit"
            dark
          >
            <div v-if="resetLoading" class="text-white">
              Send reset email
              <v-progress-circular
                class="ml-4"
                :size="21"
                indeterminate
                color="white"
              ></v-progress-circular>
            </div>
            <div class="text-white" @click="getResetPasswordLink" v-else>
              Send reset email
            </div>
          </v-btn>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5 text-center">
          Instruction email sent
        </v-card-title>

        <v-card-text class="text-h6 text-center my-3">
          Please check your email inbox for instructions on resetting your inbox
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="#F24296" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    const email = ref("");
    const token = ref("");
    const resetLoading = ref(false);
    const showReset = ref(true);
    const errorAlert = ref(false);
    const errorMessage = ref("");
    const dialog = ref(false);
    const show = ref(false);

    onMounted(() => {
      //   token.value = this.$route.params.token;
      //   console.log(token.value);
    });

    const getResetPasswordLink = async () => {
      resetLoading.value = true;
      try {
        const response = await axios.post(
          process.env.VUE_APP_BACKEND_API_URL + "api/password_reset/",
          { email: email.value }
        );
        // Handle the successful response here if needed
        if (response.status == 200) {
          dialog.value = true;
        }
        resetLoading.value = false;
        return response.data;
      } catch (error) {
        // Handle the error here
        console.error("Error in getResetPasswordLink:", error);
        errorMessage.value = "Error please try again";
        resetLoading.value = false;
      }
    };

    return {
      email,
      token,
      resetLoading,
      showReset,
      errorAlert,
      errorMessage,
      dialog,
      show,
      getResetPasswordLink,
    };
  },
};
</script>
<style scoped>
.mainContainer {
  background-color: white;
  height: 100%;
}
</style>
