<template>
  <div>
    <v-footer class="container text-center d-flex flex-column text-white">
      <div class="pt-5 title">Contact us</div>
      <div class="about">artists@gummint.store</div>
      <div class="d-flex my-4">
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-2"
          :icon="icon"
          variant="text"
        ></v-btn>
        <a class="instagram mt-2" href="https://www.instagram.com/gummint_music" target="_blank">gummint_music</a>
      </div>

      <v-divider></v-divider>

      <div>
        ©{{ new Date().getFullYear() }} —
        <strong> Cybafrican Tech. All rights reserved.</strong>
      </div>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    icons: ["mdi-instagram"],
  }),
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.about {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  max-width: 1400px;
}

.instagram {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  cursor: pointer; /* Show cursor as pointer on hover */
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
}
.container {
  /* background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  ); */
  background-color: rgb(29, 29, 29);
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  /* backdrop-filter: blur(10px); */
}
</style>
