<template>
  <div class="recentSection">
    <div class="text-center">
      <p class="text-white titleText pt-3">Recent Songs</p>
    </div>
    <div class="mt-4 mb-10 d-flex justify-center">
      <v-btn
        class="subtitleText seeButtonBottom px-5"
        @click="navigateToExplore"
        >see all</v-btn
      >
    </div>

    <div v-if="loading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-if="!loading" class="nftcollection-grid mt-4">
      <div v-for="song in nftSongs" :key="song">
        <CollectionCard class="my-1" :collectionData="song" />
      </div>
    </div>
  </div>
</template>

<script>
import NFTCard from "./NFTCard.vue";
import CollectionCard from "./CollectionCard.vue";
import RecentDropCard from "./RecentDropCard.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";

export default {
  name: "RecentSongs",

  components: {
    NFTCard,
    CollectionCard,
    RecentDropCard,
  },
  setup() {
    const allNFTs = ref([]);
    const loadings = ref(true);
    const nftSongs = ref([]);

    onMounted(() => {
      refetch();
    });

    const { result, loading, error, onResult, refetch } = useQuery(
      gql`
        query allCollection {
          allCollection {
            id
            name
            about
            image
            genre
          }
        }
      `
    );

    watch(result, (value) => {
      nftSongs.value = value.allCollection;
    });

    const navigateToUser = (id) => {
      router.push({ path: `/collection/${id}` });
    };

    const navigateToExplore = () => {
      router.push({ path: "/explore" });
    };

    return {
      allNFTs,
      loadings,
      nftSongs,
      loading,
      navigateToExplore,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.rowContainer {
  position: relative;
  height: 450px;
}

.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 100%;
  /* margin-inline: auto; */
}

@media screen and (min-width: 370px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.3rem;
    padding-left: 10px;
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
.recentSection {
  /* background: #3c3c3c; */
  border-radius: 0px;
  height: auto;
  border: none;
  /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.2); */
  /* outline: none; */
  /* background: linear-gradient(
      108.46deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.03) 100%
    ); */
  /* backdrop-filter: blur(20px); */
  max-width: 1700px;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* 100% of the viewport height */
  width: 100%;
}

.titleText {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitleText {
  font-size: 18px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}
.horizontalScroll {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  overflow-x: scroll;
  height: auto;
  width: 100%;
}
.horizontalScroll:not(:hover)::-webkit-scrollbar {
  display: none;
}
.horizontalScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #333;
}

.horizontalScroll::-webkit-scrollbar-track {
  background-color: #222;
}

.horizontalScroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #4d4d4d;
}

.horizontalScroll::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.seeButtonBottom {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .glassmorphism {
    height: 460px;
  }
  .rowContainer {
    height: 330px;
  }

  .titleText {
    font-size: 25px;
  }
}
</style>
