<template>
    <img
      :src="coverPicture || 'https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/grad11.jpg'"
      class="backgroundCover"
    />
  </template>
  
  <script>
  export default {
    name: "BackgroundCover",
    props: {
      coverPicture: String,
    },
  };
  </script>
  
  <style scoped>
  .backgroundCover {
    position: absolute;
    width: 100%;
    height: 340px;
    top: -100px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }
  </style>