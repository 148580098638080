<!-- src/components/AccountInfo.vue -->
<template>
  <div class="px-2">
    <!-- <p class="text-h6 text-grey mb-6">
      <v-icon size="20" class="mr-1">mdi-email-outline</v-icon>{{ email }}
    </p> -->
    <div class="mt-4">
      <v-row>
        <v-col cols="12">
          <AccountEarningsOverview
            :totalStreams="totalStreams"
            :totalEarnings="totalRoyaltyIncome"
            :ownedNFTsCount="ownedNFTs.length"
            @withdraw="withdrawEarnings"
          />
        </v-col>
      </v-row>

      <!-- New Withdraw Section -->

      <v-btn
        v-if="isAccountOwner && isAuthenticated && web3Enabled"
        size="large"
        block
        class="btn btn-3 large mt-4 mx-auto"
        @click="$emit('showWallet')"
      >
        <v-icon class="mr-1">mdi-wallet</v-icon>View Crypto wallet
      </v-btn>
    </div>
    <Dashboard
      :userId="userId"
      :combinedStreamingStats="combinedStreamingStats"
    />
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import Dashboard from "./Dashboard.vue";
import AccountEarningsOverview from "./AccountEarningsOverview.vue";

export default {
  components: {
    Dashboard,
    AccountEarningsOverview,
  },
  props: {
    email: String,
    isAccountOwner: Boolean,
    isAuthenticated: Boolean,
    web3Enabled: Boolean,
    userId: Number,
    ownedNFTs: Array,
  },
  emits: ["showWallet"],
  setup(props) {
    const totalRoyaltyIncome = ref(0);
    const totalStreams = ref(0);
    const combinedStreamingStats = ref([]);
    const totalOwnershipPercentage = ref(0);

    const { refetch: fetchDropInfo } = useQuery(
      gql`
        query dropsByNfttokenid($id: Int!) {
          dropsByNfttokenid(id: $id) {
            id
            collection {
              id
              earnings
              streamQuantity
            }
            royaltyPercentage
          }
        }
      `,
      () => ({
        id: 0,
      })
    );

    const { refetch: fetchStreamingStats } = useQuery(
      gql`
        query GetStreamingStats($collectionId: Int!) {
          streamingStatsByCollection(collectionId: $collectionId) {
            platform {
              name
            }
            weeklyStats
            monthlyStats
            projectedStats
          }
        }
      `,
      () => ({
        collectionId: 0,
      })
    );

    const fetchAndCombineStats = async () => {
      let income = 0;
      let streams = 0;
      const collectionStats = new Map();

      for (const nft of props.ownedNFTs) {
        const { data: dropData } = await fetchDropInfo({
          id: Number(nft.tokenId),
        });
        if (dropData?.dropsByNfttokenid?.[0]) {
          const drop = dropData.dropsByNfttokenid[0];
          const collection = drop.collection;
          const royaltyPercentage = Number(drop.royaltyPercentage);

          // Calculate income based on collection earnings and royalty percentage
          income += collection.earnings * (royaltyPercentage / 100);
          streams += collection.streamQuantity;

          if (!collectionStats.has(collection.id)) {
            const { data: statsData } = await fetchStreamingStats({
              collectionId: Number(collection.id),
            });
            if (statsData?.streamingStatsByCollection) {
              collectionStats.set(
                collection.id,
                statsData.streamingStatsByCollection
              );
            }
          }
        }
      }

      totalRoyaltyIncome.value = income;
      totalStreams.value = streams;
      combinedStreamingStats.value = Array.from(
        collectionStats.values()
      ).flat();
    };

    const withdrawEarnings = () => {
      // Implement withdrawal logic here
      console.log("Withdrawing R" + totalRoyaltyIncome.value);
      // You can add API calls or blockchain transactions here
    };

    watch(
      () => props.ownedNFTs,
      async () => {
        if (props.ownedNFTs.length > 0) {
          await fetchAndCombineStats();
        }
      },
      { immediate: true }
    );

    return {
      totalRoyaltyIncome,
      totalStreams,
      combinedStreamingStats,
      withdrawEarnings,
    };
  },
};
</script>
<style scoped>
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}

.buyBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  /* width: 210px; */
  border-color: white;
  border-width: 2px;
  color: white;
  box-shadow: 0 0 2px #eee;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  /* background-image: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%); */
}
</style>
