<template>
  <v-app>
    <v-main class="appbackground">
      <NavBar />
      <div class="glassEffect"></div>

      <div class="text-center contentContainer my-14">
        <p class="text-white text-h2">Create music NFT</p>
        <p class="text-white text-h6">
          Mint music NFT and create collection albums for Gummint
        </p>
      </div>
      <div class="glassEffect"></div>
      <v-row>
        <v-col>
          <v-card width="450" class="mx-auto playcard mt-10">
            <v-card-title class="text-white text-center mb-3 text-h5"
              >Create collection album</v-card-title
            >
            <v-card-text class="text-white">
              <v-text-field
                label="Collection album name"
                v-model="collectionName"
              />
              <v-text-field
                label="About collection"
                v-model="aboutCollection"
              />
              <v-text-field label="Genre" v-model="collectionGenre" />
              <v-text-field label="Artist id" v-model="artistId" />
              <v-file-input
                label="Select collection album image"
                ref="file"
                @change="uploadCollectionImage($event)"
              />
              <div class="mx-auto text-center">
                <v-btn
                  class="createCollection my-3 mx-auto"
                  @click="createCollection"
                  >Create Collection</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card width="450" class="mx-auto playcard mt-10">
            <v-card-title class="text-white text-center mb-3 text-h5"
              >Create music NFT</v-card-title
            >
            <v-card-text class="text-white">
              <v-select
                class="mt-6"
                v-model="selectedCollection"
                :items="nftCollections"
                item-title="about"
                item-value="id"
                label="Select a collection album for NFT"
                return-object
              >
              </v-select>
              <v-text-field label="Music NFT Name" v-model="nftName" />
              <v-select
                v-model="selectedEdition"
                :items="nftEditions"
                item-title="Edition"
                item-value="id"
                label="Select NFT edition"
                return-object
              >
              </v-select>
              <v-text-field label="main artist" v-model="mainArtist" />
              <v-text-field
                label="Featured artists (comma seperated)"
                v-model="featuredArtists"
              />

              <v-text-field label="Genre" v-model="nftGenre" />
              <v-text-field label="Royalty type" v-model="royaltyType" />
              <v-text-field
                label="Life of rights"
                type="number"
                v-model="lifeOfRights"
              />

              <label for="selectedDate" class="text-white text-h6 ml-1"
                >Song release date:
              </label>
              <input
                type="date"
                id="selectedDate"
                v-model="songReleaseDate"
                class="my-3 text-white text-h6"
              />
              <v-row>
                <v-col>
                  <v-text-field label="Price" v-model="price" type="number" />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Royalty percentage"
                    v-model="royaltyPercentage"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-file-input
                label="Select music NFT image"
                ref="file"
                @change="uploadImage($event)"
              />
              <v-file-input
                label="Sample audio"
                ref="file"
                @change="uploadAudio($event)"
              />
            </v-card-text>
            <v-card-actions>
              <div v-if="!creationLoading" class="mx-auto">
                <v-btn class="buyBtn my-3 mx-auto" @click="listNFT()"
                  >Create NFT</v-btn
                >
              </div>
              <div v-if="creationLoading" class="mx-auto">
                <v-btn class="buyBtn my-3 mx-auto"
                  >Creating NFT
                  <v-progress-circular
                    indeterminate
                    color="white"
                    :size="30"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { uploadFileToIPFS } from "../pinata";
import { uploadJSONToIPFS } from "../pinata";
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import Marketplace from "../Marketplace.json";
import CreateNft from "../CreateNft.json";
import { ethers } from "ethers";
import axios from "axios";
import gql from "graphql-tag";
import {
  GelatoRelay,
  SponsoredCallERC2771Request,
} from "@gelatonetwork/relay-sdk";

export default {
  name: "MintPage",
  components: {},
  setup() {
    const nftName = ref("");
    const mainArtist = ref("");
    const featuredArtists = ref("");
    const royaltyType = ref("");
    const royaltyPercentage = ref(null);
    const price = ref("");
    const image = ref(null);
    const file = ref(null);
    const creationLoading = ref(false);
    const numberOfNfts = ref(null);
    const selectedItem = ref(null);
    const collectionName = ref("");
    const aboutCollection = ref("");
    const collectionGenre = ref("");
    const collectionImage = ref("");
    const nftGenre = ref("");
    const artistId = ref(null);
    const nftCollections = ref([]);
    const songReleaseDate = ref(null);
    const lifeOfRights = ref(null);
    const selectedCollection = ref(null);
    const audiofile = ref(null);
    const relay = new GelatoRelay();
    const nftEditions = ref(["Bronze", "Gold", "Platinum"]);
    const selectedEdition = ref(null);
    console.log(selectedEdition.toString());
    onMounted(() => {
      console.log(process.env);
      refetch();
    });

    const { result, error, loading, refetch } = useQuery(
      gql`
        query allCollection {
          allCollection {
            id
            name
            about
            image
            artist {
              name
            }
            genre
          }
        }
      `
    );

    watch(result, (value) => {
      nftCollections.value = value.allCollection;
      console.log(nftCollections.value);
    });

    async function createCollection() {
      try {
        const formData = new FormData();
        formData.append("name", collectionName.value);
        formData.append("about", aboutCollection.value);
        formData.append("genre", collectionGenre.value);
        formData.append("artist_id", artistId.value);

        if (collectionImage.value) {
          formData.append(
            "image",
            collectionImage.value,
            collectionImage.value.name
          );
        }

        const headers = {
          Authorization: "Token dsfwsfw434435345dgdtggtdgdtgdtgdgtdgdsdwe",
        };

        const response = await axios.post(
          process.env.VUE_APP_BACKEND_API_URL + "collections/createcollection/",
          formData,
          { headers }
        );
        console.log("Response:", response.data);
        // Handle the response data here if needed
      } catch (error) {
        console.error("Error:", error);
        // Handle the error here if needed
      }
    }

    async function uploadNFTDataBackend(nfttokenid) {
      try {
        const formData = new FormData();
        formData.append("name", nftName.value);
        formData.append("nfttokenid", nfttokenid);
        formData.append("genre", nftGenre.value);
        formData.append("price", price.value);
        formData.append("royaltyPercentage", royaltyPercentage.value);
        formData.append("edition", selectedEdition.value);
        formData.append("royaltyType", royaltyType.value);
        formData.append("collection_id", selectedCollection.value.id);
        formData.append("lifeOfRights", lifeOfRights.value);
        formData.append("songReleaseDate", songReleaseDate.value);

        if (file.value) {
          formData.append("image", file.value, file.value.name);
        }

        if (audiofile.value) {
          formData.append("nftsound", audiofile.value, audiofile.value.name);
        }

        const headers = {
          Authorization: "Token " + process.env.VUE_APP_BACKEND_TOKEN,
        };

        const response = await axios.post(
          process.env.VUE_APP_BACKEND_API_URL + "collections/addsong/",
          formData,
          { headers }
        );
        console.log("Response:", response.data);
        // Handle the response data here if needed
      } catch (error) {
        console.error("Error:", error);
        // Handle the error here if needed
      }
    }

    // async function fetchNftCollections() {
    //   try {
    //     const response = await axios.get(
    //       "http://127.0.0.1:8000/collections/getcollection/"
    //     );
    //     nftCollections.value = response.data;
    //     console.log(nftCollections.value);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    async function uploadCollectionImage(event) {
      collectionImage.value = event.target.files[0];
      console.log(collectionImage.value);
    }

    async function uploadImage(event) {
      file.value = event.target.files[0];
      console.log(selectedCollection.value);
      console.log(file.value);
    }

    async function uploadAudio(event) {
      audiofile.value = event.target.files[0];
      console.log(audiofile.value);
    }
    //This function uploads the metadata to IPDS
    async function uploadMetadataToIPFS() {
      if (
        !nftName.value ||
        !nftGenre.value ||
        !price.value ||
        !image.value ||
        !royaltyPercentage.value ||
        !selectedCollection.value ||
        !royaltyType.value ||
        !songReleaseDate.value ||
        !lifeOfRights.value ||
        !selectedEdition.value ||
        !featuredArtists.value ||
        !mainArtist.value
      ) {
        alert("fill in all details");

        return;
      }

      console.log(selectedCollection.value);

      const nftJSON = {
        name: nftName.value,
        description: nftName.value + " music NFT by Gummint",
        external_url: "https://gummint.store",
        image: image.value,
        attributes: [
          {
            trait_type: "Royalty share",
            value: royaltyType.value,
          },
          {
            trait_type: "Edition",
            value: selectedEdition.value,
          },
        ],
        artist1: mainArtist.value,
        featuredArtist: featuredArtists.value ? featuredArtists.value : null,
        genre: nftGenre.value,
        collectionAlbum: selectedCollection.value.name,
        aboutCollection: selectedCollection.value.about,
        collectionGenre: selectedCollection.value.genre,
        royaltyType: royaltyType.value,
        royaltyPercentage: royaltyPercentage.value,
        lifeOfRights: lifeOfRights.value + " years",
        songReleaseDate: songReleaseDate.value,
      };

      console.log(nftJSON);

      try {
        //upload the metadata JSON to IPFS
        const response = await uploadJSONToIPFS(nftJSON);
        if (response.success === true) {
          console.log("Uploaded JSON to Pinata: ", response);
          return response.pinataURL;
        }
      } catch (e) {
        console.log("error uploading JSON metadata:", e);
      }
    }

    async function listNFT() {
      // for (let i = 0; i < 5; i++) {
        creationLoading.value = true;
        try {
          //upload the file to IPFS
          console.log(file.value);
          const response = await uploadFileToIPFS(file.value);
          console.log(response);

          if (response && response.success === true) {
            console.log("Uploaded image to Pinata: ", response.pinataURL);
            image.value = response.pinataURL;

            let metadataURL = await uploadMetadataToIPFS();
            console.log("hello");
            console.log(metadataURL);
            const provider = new ethers.JsonRpcProvider(
              process.env.VUE_APP_ALCHEMY_API_URL
            );
            const signer = new ethers.Wallet(
              process.env.VUE_APP_PRIVATE_KEY,
              provider
            );
            let market = JSON.parse(Marketplace);
            let createNft = JSON.parse(CreateNft);
            const walletAddress = await signer.getAddress();
            //Pull the deployed contract instance
            let marketcontract = new ethers.Contract(
              market.address,
              market.abi,
              signer
            );
            let nftcontract = new ethers.Contract(
              createNft.address,
              createNft.abi,
              signer
            );
            // mint NFT using relayer
            console.log("Please wait.. uploading (upto 5 mins) 6");
            console.log(metadataURL);

            let mintnfts = await nftcontract.safeMint(metadataURL, {
              gasLimit: 3000000,
            });
            await mintnfts.wait();
            let nftTokenId = await nftcontract._tokenIdCounter();
            await nftcontract.approve(market.address, nftTokenId, {
              gasLimit: 3000000,
            });
            const nftprice = ethers.parseUnits(price.value, "ether");
            console.log(nftprice);
            console.log(ethers.formatUnits(nftprice, 18));

            //list the nft on the marketplace
            let transaction = await marketcontract.listItemUsingFiat(
              nftTokenId,
              {
                gasLimit: 3000000,
              }
            );
            let listingTransactionResults = await transaction.wait();
            console.log(listingTransactionResults);
            await uploadNFTDataBackend(nftTokenId);
            alert("Successfully listed your NFT!");
            // nftGenre.value = "";
            // nftName.value = "";
            // price.value = "";
            creationLoading.value = false;
          } else {
            creationLoading.value = false;
            alert("Error creating NFT please try again");
            return;
          }
        } catch (e) {
          console.log(e.message);
          creationLoading.value = false;
          alert("Upload error" + e);
        }
      // }
    }

    return {
      creationLoading,
      nftName,
      royaltyPercentage,
      price,
      image,
      uploadImage,
      numberOfNfts,
      uploadMetadataToIPFS,
      listNFT,
      selectedItem,
      artistId,
      collectionImage,
      collectionGenre,
      aboutCollection,
      collectionName,
      createCollection,
      uploadCollectionImage,
      selectedCollection,
      nftCollections,
      royaltyType,
      uploadNFTDataBackend,
      uploadAudio,
      nftGenre,
      lifeOfRights,
      songReleaseDate,
      selectedEdition,
      nftEditions,
      featuredArtists,
      mainArtist,
    };
  },
};
</script>
<style scoped>
.appbackground {
}

.buyBtn {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  text-align: center;
  width: 200px;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

.createCollection {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  text-align: center;
  width: 280px;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

.contentContainer {
  position: relative;
  z-index: 9;
  flex: 1;
}
.playcard {
  background: #0d0d0d;
  border-radius: 32px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-blur: 80px;
}
</style>
