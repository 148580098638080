<template>
    <v-dialog
      v-model="dialogModel"
      max-width="600"
      class="editDialog mx-auto text-center"
    >
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto" style="width: 100%">
          <p class="text-h5 text-center mt-3">Banking Details</p>
          <div class="mt-4 mx-auto text-center px-3" style="max-width: 400px">
            <v-select
              v-model="selectedBank"
              :items="southAfricanBankCodes"
              label="Select Bank"
              variant="underlined"
              prepend-icon="mdi-bank"
              required
            ></v-select>
  
            <v-select
              v-model="selectedAccountType"
              :items="accountTypes"
              label="Select Bank Account Type"
              variant="underlined"
              prepend-icon="mdi-bank"
              required
            ></v-select>
            <v-text-field
              v-model="bankAccountName"
              label="Account Holder Name"
              variant="underlined"
              prepend-icon="mdi-account"
              required
            ></v-text-field>
  
            <v-text-field
              v-model="bankAccountNumber"
              label="Account Number"
              variant="underlined"
              prepend-icon="mdi-numeric"
              required
            ></v-text-field>
  
            <div class="text-center">
              <v-btn
                class="mx-auto btn btn-3 mb-3"
                @click="updateBankingDetails"
                :disabled="updateBankLoading"
              >
                Update details
                <v-progress-circular
                  v-if="updateBankLoading"
                  indeterminate
                  color="white"
                  :size="23"
                ></v-progress-circular>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    name: 'BankingDetailsDialog',
    props: {
      modelValue: Boolean,
      bankDetails: Object,
      southAfricanBankCodes: Array,
      accountTypes: Array,
      updateBankLoading: Boolean,
    },
    emits: ['update:modelValue', 'updateBankingDetails'],
    setup(props, { emit }) {
      const dialogModel = ref(props.modelValue);
      const selectedBank = ref(props.bankDetails.bank || null);
      const selectedAccountType = ref(props.bankDetails.accountType || null);
      const bankAccountName = ref(props.bankDetails.accountName || '');
      const bankAccountNumber = ref(props.bankDetails.accountNumber || '');
  
      watch(() => props.modelValue, (newValue) => {
        dialogModel.value = newValue;
      });
  
      watch(dialogModel, (newValue) => {
        emit('update:modelValue', newValue);
      });
  
      watch(() => props.bankDetails, (newValue) => {
        selectedBank.value = newValue.bank || null;
        selectedAccountType.value = newValue.accountType || null;
        bankAccountName.value = newValue.accountName || '';
        bankAccountNumber.value = newValue.accountNumber || '';
      });
  
      const updateBankingDetails = () => {
        const updatedDetails = {
          bank: selectedBank.value,
          accountType: selectedAccountType.value,
          accountName: bankAccountName.value,
          accountNumber: bankAccountNumber.value,
        };
        emit('updateBankingDetails', updatedDetails);
      };
  
      return {
        dialogModel,
        selectedBank,
        selectedAccountType,
        bankAccountName,
        bankAccountNumber,
        updateBankingDetails,
      };
    },
  };
  </script>
  
  <style scoped>
  .editDialog {
    width: 600px;
  }
  
  .glassmorphismDialogue {
    background: #0d0d0d;
    border-radius: 30px;
    background: linear-gradient(
      108.46deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(80px);
    min-height: 300px;
    width: 600px;
  }
  
  .btn {
    padding: 8px;
    text-align: center;
    transition: 0.5s;
    font-family: "Poppins", sans-serif;
    background-size: 200% auto;
    color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
  
  .btn-3 {
    background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  }
  
  @media screen and (max-width: 600px) {
    .editDialog,
    .glassmorphismDialogue {
      width: 95%;
    }
  }
  </style>