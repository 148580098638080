<template>
  <v-card class="earnings-card glassmorphism text-white mb-6">
    <v-card-title class="text-h5 font-weight-bold pb-2">Streaming Insights</v-card-title>
    <v-divider class="mb-4"></v-divider>
    <v-card-text>
      <v-row class="stat-row">
        <v-col cols="12" sm="6" class="stat-item">
          <div class="stat-label">Total Streams</div>
          <div class="stat-value">{{ totalStreams }}</div>
        </v-col>
        <v-col cols="12" sm="6" class="stat-item">
          <div class="stat-label">Total Earnings</div>
          <div class="stat-value">R{{ formatNumber(totalEarnings) }}</div>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row class="stat-row">
        <v-col cols="12" class="stat-item">
          <div class="stat-label">
            Potential Earnings ({{ ownershipPercentage }}% ownership)
          </div>
          <div class="stat-value highlight">
            R{{ formatNumber(potentialEarnings) }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  totalStreams: Number,
  totalEarnings: Number,
  ownershipPercentage: Number
});

const potentialEarnings = computed(() => 
  props.totalEarnings * (props.ownershipPercentage / 100)
);

const formatNumber = (value) => {
  return new Intl.NumberFormat('en-ZA', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  }).format(value);
};
</script>

<style scoped>
.earnings-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-item {
  text-align: center;
  padding: 10px;
}

.stat-label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.highlight {
  color: #4CAF50;
  font-size: 1.8rem;
}

@media (max-width: 600px) {
  .stat-value {
    font-size: 1.2rem;
  }

  .highlight {
    font-size: 1.5rem;
  }
}
</style>