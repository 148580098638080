vueCopy
<template>
  <v-app theme="dark">
    <v-main>
      <v-container fluid class="pa-0">
        <!-- Hero Section -->
        <v-container fluid class="hero-section py-16">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" lg="5" class="text-center text-md-left">
              <h1 class="text-h2 font-weight-bold mb-6">
                Revolutionize Your Event Experience
              </h1>
              <p class="text-h5 mb-8">
                Unlock a new era of event attendance with NFT tickets. Collect,
                trade, and experience exclusive perks beyond the event itself.
              </p>
              <!-- <v-btn color="primary" x-large class="mr-4 mb-4" elevation="2">
                <v-icon left>mdi-ticket-outline</v-icon>
               Learn more
              </v-btn> -->
              <!-- <v-btn outlined x-large class="mb-4" elevation="2">
                <v-icon left>mdi-information-outline</v-icon>
                Learn More
              </v-btn> -->
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="5"
              class="d-flex justify-center align-center"
            >
              <v-img
                src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/ticketbanner.png"
                max-height="500"
                contain
                class="ticket-image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-container>

        <!-- Upcoming Events Section -->
        <!-- <v-container class="py-16">
            <h2 class="text-h3 text-center mb-12 font-weight-bold">Upcoming Events</h2>
            <v-row>
              <v-col
                v-for="event in featuredEvents"
                :key="event.id"
                cols="12"
                sm="6"
                md="4"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="mx-auto transition-swing"
                    max-width="374"
                    :elevation="hover ? 12 : 2"
                  >
                    <v-img :src="event.image" height="250" cover>
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-card-title>{{ event.name }}</v-card-title>
                    <v-card-subtitle>{{ event.artist }}</v-card-subtitle>
                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-icon class="mr-1">mdi-calendar</v-icon>
                        <span>{{ event.date }}</span>
                      </v-row>
                      <v-row align="center" class="mx-0">
                        <v-icon class="mr-1">mdi-ticket</v-icon>
                        <span>{{ event.price }} ETH</span>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" block>Buy Ticket</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container> -->

        <!-- How It Works Section -->
        <v-container class="py-16 grey darken-3">
          <h2 class="text-h3 text-center mb-12 font-weight-bold">
            How It Works
          </h2>
          <v-row align="center">
            <v-col cols="12" md="6">
              <v-img
                src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/ticket+6.png"
                max-height="400"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-timeline align="start">
                <v-timeline-item
                  v-for="(step, index) in howItWorks"
                  :key="index"
                  :color="['primary', 'secondary', 'success', 'info'][index]"
                  small
                >
                  <template v-slot:icon>
                    <v-avatar color="primary">
                      <span class="text-h6">{{ index + 1 }}</span>
                    </v-avatar>
                  </template>
                  <v-card class="elevation-2 timelineCard">
                    <v-card-title class="text-h6 d-flex flex-column">{{
                      step.title
                    }}</v-card-title>
                    <v-card-text class="">{{ step.description }}</v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>

        <!-- NFT Tickets Info Section -->
        <v-container class="py-16">
          <h2 class="text-h3 text-center mb-12 font-weight-bold">
            NFT Tickets: The Future of Event Access
          </h2>
          <v-row justify="center" class="mb-12">
            <v-col cols="12" md="8">
              <v-card class="pa-6 rounded-xl" elevation="10" color="primary">
                <v-card-title class="text-h4 font-weight-bold white--text mb-4">
                  What are NFT Tickets?
                </v-card-title>
                <v-card-text class="text-h6 white--text">
                  NFT tickets are unique digital assets stored on the
                  blockchain, representing access to events and experiences.
                  Each ticket is a one-of-a-kind collectible, offering benefits
                  beyond traditional ticketing.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="pa-6 rounded-xl" elevation="5" height="100%">
                <v-card-title class="text-h4 font-weight-bold mb-4">
                  <v-icon large color="success" class="mr-2"
                    >mdi-account-check</v-icon
                  >
                  Tickets benefits
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="(benefit, index) in buyerBenefits"
                      :key="index"
                    >
                      <v-list-item-icon>
                        <v-icon color="success">{{ benefit.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-h6">{{
                          benefit.title
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          benefit.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="pa-6 rounded-xl" elevation="5" height="100%">
                <v-card-title class="text-h4 font-weight-bold mb-4">
                  <v-icon large color="info" class="mr-2">mdi-palette</v-icon>
                  Benefits for Artists
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="(benefit, index) in artistBenefits"
                      :key="index"
                    >
                      <v-list-item-icon>
                        <v-icon color="info">{{ benefit.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-h6">{{
                          benefit.title
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          benefit.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- Exclusive Offerings Section -->
        <v-container class="py-16 grey darken-3">
          <h3 class="text-h3 text-center mb-12 font-weight-bold">
            Exclusive Offerings
          </h3>
          <v-row>
            <v-col
              v-for="(offering, index) in exclusiveOfferings"
              :key="index"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card
                class="pa-4 rounded-xl text-center"
                elevation="5"
                height="100%"
              >
                <v-avatar
                  size="80"
                  :color="['primary', 'secondary', 'success', 'info'][index]"
                  class="mb-4"
                >
                  <v-icon dark x-large>{{ offering.icon }}</v-icon>
                </v-avatar>
                <v-card-title class="justify-center text-h5 font-weight-bold">{{
                  offering.title
                }}</v-card-title>
                <v-card-text>{{ offering.description }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- Newsletter Section -->
        <v-container fluid class="py-16 newsletter-bg">
          <v-row justify="center">
            <v-col cols="12" md="8" lg="6" class="text-center">
              <h2 class="text-h3 mb-4 font-weight-bold white--text">
                Stay Updated
              </h2>
              <p class="text-h6 mb-6 white--text">
                Subscribe to our newsletter for exclusive offers and the latest
                NFT ticket drops.
              </p>
              <v-form @submit.prevent="subscribeNewsletter">
                <v-text-field
                  v-model="email"
                  label="Enter your email"
                  type="email"
                  required
                  outlined
                  dark
                  class="mb-4"
                ></v-text-field>
                <v-btn type="submit" color="primary" x-large block
                  >Subscribe</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>

    <BottomFooter class="footer" />

  </v-app>
</template>

<script setup>
import { ref, onMounted } from "vue";
import BottomFooter from "../../components/BottomFooter.vue";
onMounted(() => {
      window.scrollTo(0, 0);
    });

const buyerBenefits = [
  {
    icon: "mdi-palette",
    title: "Unique Digital Art",
    description: "Own one-of-a-kind collectible ticket designs",
  },
  {
    icon: "mdi-shield-check",
    title: "Verifiable Authenticity",
    description: "Blockchain-backed security and easy transfers",
  },
  {
    icon: "mdi-star",
    title: "Exclusive Access",
    description: "Unlock special content and experiences",
  },
  {
    icon: "mdi-trending-up",
    title: "Potential Value Growth",
    description: "NFT tickets may appreciate over time",
  },
];

const artistBenefits = [
  {
    icon: "mdi-account-group",
    title: "Fan Connection",
    description: "Build stronger relationships with your audience",
  },
  {
    icon: "mdi-cash-multiple",
    title: "New Revenue Streams",
    description: "Earn from digital art sales and royalties",
  },
  {
    icon: "mdi-ticket-percent",
    title: "Distribution Control",
    description: "Manage ticket sales with greater flexibility",
  },
  {
    icon: "mdi-chart-bar",
    title: "Data Insights",
    description: "Gain valuable data on your fan base",
  },
];

const exclusiveOfferings = [
  {
    icon: "mdi-headphones",
    title: "Artist Listening Parties",
    description:
      "Join intimate listening sessions with artists before album releases.",
  },
  {
    icon: "mdi-video",
    title: "Exclusive Content Access",
    description:
      "Unlock behind-the-scenes footage, interviews, and unreleased tracks.",
  },
  {
    icon: "mdi-star",
    title: "VIP Event Packages",
    description:
      "Enjoy premium experiences including meet-and-greets and backstage tours.",
  },
  {
    icon: "mdi-account-group",
    title: "Community Perks",
    description:
      "Participate in exclusive online forums and vote on future events.",
  },
];

const featuredEvents = ref([
  {
    id: 1,
    name: "Digital Dreams listening party",
    artist: "Gummint",
    date: "To be anounced",
    price: 0.5,
    image:
      "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/event+3.png",
  },
  {
    id: 2,
    name: "Neon Nights",
    artist: "Gummint",
    date: "To be anounced",
    price: 0.7,
    image:
      "https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/event+2.png",
  },
]);

const howItWorks = [
  {
    title: "Sign in",
    description: "Link your crypto wallet to our platform.",
  },
  {
    title: "Browse Events",
    description: "Explore upcoming events with exlcusive NFT perks.",
  },
  {
    title: "Buy Ticket",
    description: "Buy your NFT ticket securely.",
  },
  {
    title: "Attend Event",
    description:
      "Show your NFT ticket at the venue for entry and enjoy the VIP show!",
  },
];

const featuredArtist = {
  name: "Crypto Canvas",
  genre: "Digital Surrealism",
  bio: "Crypto Canvas is a pioneering digital artist known for mind-bending surrealist creations that blur the line between reality and imagination.",
  nfts: [
    { id: 1, image: "/path/to/nft1.jpg" },
    { id: 2, image: "/path/to/nft2.jpg" },
    { id: 3, image: "/path/to/nft3.jpg" },
    { id: 4, image: "/path/to/nft4.jpg" },
  ],
};

const testimonials = [
  {
    id: 1,
    quote:
      "NFTickets has revolutionized how I attend events. The unique NFT art makes each ticket a cherished collectible!",
    name: "Alex Johnson",
    title: "Crypto Enthusiast",
    avatar: "/path/to/avatar1.jpg",
  },
  {
    id: 2,
    quote:
      "As an artist, NFTickets has opened up new ways for me to connect with fans and monetize my work. It's a game-changer!",
    name: "Samantha Lee",
    title: "Digital Artist",
    avatar: "/path/to/avatar2.jpg",
  },
  {
    id: 3,
    quote:
      "The security and authenticity of NFT tickets give me peace of mind. Plus, the community aspects are fantastic!",
    name: "Michael Chen",
    title: "Event Organizer",
    avatar: "/path/to/avatar3.jpg",
  },
];

const email = ref("");

const subscribeNewsletter = () => {
  // Implement newsletter subscription logic here
  console.log("Subscribed with email:", email.value);
  // Reset email field
  email.value = "";
};
</script>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}
.newsletter-bg {
  background-image: linear-gradient(
      to right,
      rgba(30, 136, 229, 0.8),
      rgba(21, 101, 192, 0.8)
    ),
    url("https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/newsletter-bg.jpg");
  background-size: cover;
  background-position: center;
}

.timelineCard {
  overflow: hidden;
}
.v-card {
  transition: all 0.3s ease;
}
.v-card:hover {
  transform: translateY(-5px);
}
.hero-section {
  background: linear-gradient(
      to right,
      rgba(30, 30, 30, 0.9),
      rgba(30, 30, 30, 0.7)
    ),
    url("https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/tickets/hero-bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.ticket-image {
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.2));
  transition: all 0.3s ease;
}

.ticket-image:hover {
  transform: translateY(-10px);
  filter: drop-shadow(0 0 40px rgba(255, 255, 255, 0.3));
}

@media (max-width: 960px) {
  .hero-section {
    text-align: center;
  }

  .timelineCard {
    width: 160px;
  }
}
</style>
