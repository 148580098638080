import {
  // createWeb3Modal,
  // defaultConfig,
  // useWeb3Modal,
  // useWeb3ModalAccount,
  // useDisconnect,
} from "@web3modal/ethers/vue";
import axios from "axios";
import { Magic } from "magic-sdk";
import { useAuthenticationStore } from "../stores/authentication";
// import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

let instance = null;

class AuthManager {
  web3Modal = null;
  magic = null;
  userAuth = null;
  router = useRouter();

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;

    // Initialize Web3Modal
    this.userAuth = useAuthenticationStore();
    // this.initWeb3Modal();
    // Initialize Magic SDK with your API key from environment variables
    const customNodeOptions = {
      rpcUrl: process.env.VUE_APP_ALCHEMY_API_URL, // Polygon RPC URL
      chainId: process.env.VUE_APP_CHAINID, // Polygon chain id
    };

    this.magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY, {
      network: customNodeOptions,
    });
  }

  // initWeb3Modal() {
  //   // Set chains and metadata for Web3Modal
  //   const mainnet = {
  //     chainId: parseInt(process.env.VUE_APP_CHAINID, 10),
  //     name: "Polygon",
  //     currency: "MATIC",
  //     explorerUrl: "https://polygonscan.com",
  //     rpcUrl: "https://cloudflare-eth.com",
  //   };

  //   const metadata = {
  //     name: "Gummint",
  //     description: "Music NFT store",
  //     url: "https://gummint.store",
  //     icons: ["https://avatars.mywebsite.com/"],
  //   };

  //   const projectId = process.env.VUE_APP_WEB3MODAL_PROJECTID;
  //   if (!projectId) {
  //     throw new Error("Project ID not found in environment variables");
  //   }

  //   // Create Web3Modal instance
  //   this.web3Modal = createWeb3Modal({
  //     ethersConfig: defaultConfig({ metadata }),
  //     chains: [mainnet],
  //     projectId,
  //     enableAnalytics: true,
  //     enableOnramp: true,
  //   });
  // }

  // async loginWithWeb3Modal() {
  //   try {
  //     const { accounts } = storeToRefs(this.userAuth);
  //     const { isAuthenticated } = storeToRefs(this.userAuth);
  //     const { open } = useWeb3Modal();

  //     const { address } = useWeb3ModalAccount();
  //     console.log("address " + address.value);

  //     this.web3Modal = address;
  //     this.userAuth.setUserWalletAddress(address.value);
  //     open();
  //     console.log("hello");
  //     console.log(isAuthenticated.value);
  //     console.log(accounts.value);
  //   } catch (error) {
  //     console.error("Error logging in with Web3Modal:", error);
  //     throw error;
  //   }
  // }

  async loginWithMagicWallet(useremail) {
    try {
      console.log(useremail);
      // Perform login with email and password using Magic SDK
      const idToken = await this.magic.auth.loginWithEmailOTP({
        email: useremail,
      });
      console.log("Login successful");
      const { email, publicAddress } = await this.magic.user.getMetadata();
      if (email && publicAddress) {
        this.userAuth.setUserDetailsFromWallet(email, publicAddress);
      }
      this.userAuth.setMagicAuthToken(idToken);
      this.router.push({ name: "AccountPage", params: { id: publicAddress } });
    } catch (error) {
      console.error("Error logging in with Magic Wallet:", error);
      throw error;
    }
  }

  async loginWithBackend(email, password) {
    try {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_API_URL + "authenticate/login",
        formData
      );

      console.log(response.data);
      const { token, user_id, username, user_type, type_id } = response.data;
      this.userAuth.setbackendDetails(
        username,
        user_id,
        token,
        user_type,
        type_id
      );
      this.userAuth.setIsAdmin(false);
    } catch (error) {
      console.error("Error logging in with Magic Wallet:", error);
      throw error;
    }
  }

  async logout() {
    try {
      const isLoggedInWithMagic = await this.isLoggedInWithMagic();
      // const isLoggedInWithWeb3Modal = await this.isLoggedInWithWeb3Modal();
      if (isLoggedInWithMagic) {
        await this.magic.user.logout();
        console.log("Magic user logged out");
      }
      // } else if (isLoggedInWithWeb3Modal) {
      //   const { disconnect } = useDisconnect();
      //   disconnect();
      // }
      this.userAuth.clearCookies();
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  }

  async isLoggedInWithMagic() {
    try {
      // Check if user is logged in with Magic SDK
      let isLoggedInWithMagic = await this.magic.user.isLoggedIn();
      console.log(isLoggedInWithMagic);
      return isLoggedInWithMagic;
    } catch (error) {
      console.error("Error checking Magic login status:", error);
      return false;
    }
  }

  async isLoggedInWithWeb3Modal() {
    if (this.web3Modal) {
      return true;
    }
    return false;
  }

  async isLoggedIn() {
    return this.isLoggedInWithMagic;
  }

  async getCurrentAuthenticationMethod() {
    try {
      const isLoggedInWithMagic = await this.isLoggedInWithMagic();
      // const isLoggedInWithWeb3Modal = await this.isLoggedInWithWeb3Modal();

      if (isLoggedInWithMagic) {
        return "magicwallet";
      // } else if (isLoggedInWithWeb3Modal) {
      //   return "web3modal";
      } else {
        return "none";
      }
    } catch (error) {
      console.error("Error determining current authentication method:", error);
      return "unknown";
    }
  }
}

export default AuthManager;
