<template>
  <v-main>
    <div class="navbar">
      <v-navigation-drawer
        temporary
        v-model="drawer"
        theme="dark"
        class="glassTheme"
      >
        <!-- <v-spacer class="spaceContainer"></v-spacer> -->

        <v-list>
          <router-link to="/" class="router-link">
            <v-list-item
              class="list-item-text"
              prepend-icon="mdi-home"
              title="Home"
            >
            </v-list-item>
          </router-link>
          <router-link to="/explore" class="router-link">
            <v-list-item
              class="list-item-text"
              prepend-icon="mdi-view-dashboard"
              title="Explore Assets"
            >
            </v-list-item>
          </router-link>

          <router-link to="/authentication/register_artist" class="router-link">
            <v-list-item
              class="list-item-text"
              prepend-icon="mdi-form-select"
              title="Join as artist"
            ></v-list-item>
          </router-link>

          <div v-if="isAuthenticated" class="d-flex">
            <router-link :to="`/account/${accounts}`" class="router-link">
              <v-list-item
                class="list-item-text"
                prepend-icon="mdi-account"
                title="Account"
              ></v-list-item>
            </router-link>
          </div>

          <!-- <div v-if="isWalletConnected" class="d-flex">
          <router-link
            :to="`/account/${accounts}`"
            class="d-none d-md-flex mr-3"
          >
            <p class="text-white titleText">Account</p></router-link
          >
        </div> -->
        </v-list>

        <!-- <template v-slot:append> -->
        <div class="mt-16 mx-auto text-center">
          <div v-if="!isAuthenticated">
            <v-btn
              class="loginBtn px-15 rounded-lg"
              size="large"
              @click="navigateToLogin"
            >
              <v-icon class="mr-1">mdi-lock </v-icon>Login
            </v-btn>
            <v-btn
              class="signupBtn px-15 rounded-lg mt-5"
              size="large"
              @click="navigateToLogin"
            >
              <v-icon class="mr-1">mdi-account-plus </v-icon>Sign up
            </v-btn>
          </div>
          <div v-else>
            <v-btn class="signupBtn px-10 rounded-lg" @click="logout">
              <v-icon class="mr-1">mdi-logout</v-icon>Logout
            </v-btn>
          </div>
        </div>

        <!-- </template> -->
      </v-navigation-drawer>
      <v-app-bar
        app
        flat
        fixed
        color="transparent"
        class="appBar"
        style="z-index: 1000; backdrop-filter: blur(20px)"
      >
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-md-none"
          color="white"
        ></v-app-bar-nav-icon>

        <!-- <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <v-img
          class="logoContainer mx-2 mt-6 d-none d-md-flex"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
          alt="logo"
          max-height="80"
          max-width="80"
          contain
          @click="navigateToHome"
        >
        </v-img>
        <!-- <p class="text-white d-none d-md-flex pt-7">Beta</p> -->
        <v-img
          class="logoContainer mt-6 d-flex d-md-none"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
          alt="logo"
          max-height="90"
          max-width="90"
          contain
          @click="navigateToHome"
        ></v-img>
        <!-- <p class="text-white d-flex d-md-none pt-7">Beta</p> -->

        <v-spacer class="spaceContainer"></v-spacer>

        <!-- <div class="text-center d-none d-md-flex">
        <SearchBar></SearchBar>
      </div> -->

        <!-- <router-link to="/explore" class="d-none d-md-flex"
          ><p class="text-white titleText ml-10 mr-5">Explore</p></router-link
        > -->

        <!-- <div class="mx-4 d-none d-md-flex">
          <router-link to="/authentication/register_artist" class="router-link">
            <p class="text-white titleText">Artist apply</p>
          </router-link>
        </div> -->
        <div v-if="isAuthenticated" class="d-flex">
          <router-link :to="`/account/${accounts}`" class="d-none d-md-flex">
            <p class="text-white titleText mr-3">Account</p></router-link
          >
        </div>
        <!-- <v-btn
          class="btnConnect px-1 mr-4 d-none d-md-flex"
          @click="connectWallet"
          >Connect wallet</v-btn
        > -->
        <!-- <v-spacer></v-spacer> -->
        <div v-if="!isAuthenticated">
          <v-btn
            class="btnLogin px-1 mr-4 d-none d-md-flex"
            @click="navigateToLogin"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-lock </v-icon>Login
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="white"
              class="ml-3"
              :size="22"
            ></v-progress-circular>
          </v-btn>
        </div>
        <div v-else>
          <v-btn class="btnLogin px-1 mr-4 d-none d-md-flex" @click="logout">
            <v-icon class="mr-1">mdi-logout</v-icon>Logout
          </v-btn>
        </div>
      </v-app-bar>

      <v-dialog v-model="showModal" width="600">
        <v-card class="rounded-xl glassmorphism elevation-15">
          <div class="mx-auto">
            <v-img
              src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
              alt="logo"
              height="200"
              width="200"
              contain
            ></v-img>
          </div>
          <v-card-title class="text-white text-center titleMargin"
            >Artist application</v-card-title
          >
          <p class="modalSubtitleText text-center">
            To apply for a music digital asset drop with us please submit your artist
            information and our team will review your application
          </p>

          <v-card-text>
            <v-form @submit.prevent="submitForm" class="text-white">
              <v-text-field
                v-model="artistName"
                variant="underlined"
                label="Artist Name"
                :clearable="true"
                prepend-icon="mdi-account"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                variant="underlined"
                :clearable="true"
                prepend-icon="mdi-email-outline"
                label="Email"
                type="email"
                required
              ></v-text-field>
              <v-text-field
                v-model="musicLink"
                :clearable="true"
                prepend-icon="mdi-link"
                variant="underlined"
                label="Link to Music Platform"
                required
              ></v-text-field>
              <v-text-field
                v-model="phoneNumber"
                prepend-icon="mdi-phone-outline"
                variant="underlined"
                :clearable="true"
                label="Phone Number"
              ></v-text-field>
              <v-checkbox
                v-model="signedToLabel"
                variant="underlined"
                label="Signed to a Label"
              ></v-checkbox>
              <div v-if="!submitLoading" class="text-center">
                <v-btn
                  size="large"
                  class="mx-auto btnRequest px-15 rounded-lg mb-3"
                  type="submit"
                  @click="submitForm"
                  >Submit application</v-btn
                >
              </div>
              <div v-if="submitLoading" class="text-center">
                <v-btn
                  class="mx-auto btnRequest px-15 rounded-lg mb-3"
                  type="submit"
                  size="large"
                  >Submit application
                  <v-progress-circular
                    indeterminate
                    color="white"
                    :size="30"
                  ></v-progress-circular
                ></v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showLoginModal" width="600">
        <v-card class="rounded-xl glassmorphism elevation-15">
          <div class="mx-auto">
            <v-img
              src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/GUMMINT.png"
              alt="logo"
              height="200"
              width="200"
              contain
            ></v-img>
          </div>
          <v-card-title class="text-white text-center titleMargin"
            >Welcome to Gummint</v-card-title
          >
          <p class="modalSubtitleText text-center">
            Click the button below to sign in
          </p>

          <v-card-text>
            <v-text-field
              class="text-white"
              v-model="loginEmail"
              variant="underlined"
              :clearable="true"
              prepend-icon="mdi-email-outline"
              label="Email"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              class="text-white"
              v-model="userName"
              variant="underlined"
              :clearable="true"
              prepend-icon="mdi-account"
              label="User Name"
              required
            ></v-text-field>
            <v-text-field
              class="text-white"
              v-model="password"
              variant="underlined"
              :clearable="true"
              prepend-icon="mdi-lock-outline"
              label="Password"
              type="password"
              required
            ></v-text-field>
            <div v-if="!signupLoading" class="text-center">
              <v-btn
                class="mx-auto btnSubmit mb-3"
                size="x-large"
                @click="login"
              >
                <v-icon start icon="mdi-arrow-left"></v-icon>Sign in</v-btn
              >
            </div>
            <div v-if="signupLoading" class="text-center">
              <v-btn class="mx-auto btnSubmit mb-3" size="x-large" type="submit"
                >Signing in
                <v-progress-circular
                  indeterminate
                  color="white"
                  :size="30"
                ></v-progress-circular
              ></v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-main>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { Magic } from "magic-sdk";
import { useAuthenticationStore } from "../stores/authentication";
import { storeToRefs } from "pinia";
import SearchBar from "./SearchBar.vue";
import { useRoute } from "vue-router";
import router from "../router";
import { OAuthExtension } from "@magic-ext/oauth";
import AuthManager from "../authentication/authentication";

export default {
  name: "NavBar",

  components: {
    SearchBar,
  },

  setup() {
    const loading = ref(false);
    const submitLoading = ref(false);
    const showModal = ref(false);
    const artistName = ref("");
    const useremail = ref("");
    const musicLink = ref("");
    const phoneNumber = ref("");
    const signedToLabel = ref(false);
    const useraccounts = null;
    const drawer = ref(false);
    // user athentication store state and methods
    const authManager = new AuthManager();
    const userName = ref("");
    const password = ref("");
    const userAuth = useAuthenticationStore();
    const loginEmail = ref("");
    const { email } = storeToRefs(userAuth);
    const { accounts } = storeToRefs(userAuth);
    const { isAuthenticated } = storeToRefs(userAuth);
    const { userId } = storeToRefs(userAuth);
    const { clearCookies } = userAuth;
    const showLoginModal = ref(false);
    const signupLoading = ref(false);
    const route = useRoute();

    onMounted(() => {
      console.log(accounts.value);
    });

    const navigateToHome = () => {
      // Only navigate if the current route is not the home page
      if (route.name !== "Home") {
        router.push({ name: "Home" }); // Navigate to the Home route
      }
    };

    const navigateToLogin = () => {
      if (route.name !== "Login") {
        router.push({ name: "Login" }); // Navigate to the Home route
      }
    };
    console.log("my account: "  + accounts.value);

    function submitForm() {
      submitLoading.value = true;
      const formData = new FormData();
      formData.append("artistname", artistName.value);
      formData.append("email", email.value);
      formData.append("linkToMusicPlatform", musicLink.value);
      formData.append("phonenumber", phoneNumber.value);
      formData.append("signedtoalabel", signedToLabel.value);
      // Submit the form data using an HTTP request, e.g. using Axios:
      axios
        .post("http://127.0.0.1:8000/artists/artistapllication/", formData)
        .then((response) => {
          console.log(response.data);
          submitLoading.value = false;
          showModal.value = false; // Close the modal after submission
        })
        .catch((error) => {
          submitLoading.value = false;
          console.error(error);
        });
    }

    const connectWallet = () => {
      authManager.loginWithWeb3Modal();
    };

    function openModal() {
      showModal.value = true;
    }

    const logout = async () => {
      authManager.logout();
    };

    return {
      logout,
      useraccounts,
      loading,
      showModal,
      artistName,
      useremail,
      musicLink,
      phoneNumber,
      signedToLabel,
      submitForm,
      openModal,
      submitLoading,
      isAuthenticated,
      drawer,
      navigateToHome,
      showLoginModal,
      signupLoading,
      loginEmail,
      password,
      userName,
      navigateToLogin,
      userId,
      accounts,
      connectWallet,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.round {
  border-radius: 60px;
}

.navbar {
}

.list-item-text {
  color: white; /* Change to your desired text color */
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.list-item-title {
  font-size: 16px; /* Adjust the font size as needed */
}

/* Custom styling for the router link */
.router-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from parent */
  cursor: pointer; /* Show pointer cursor on hover */
}
.drawer-container {
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1200;
  overflow: hidden;
}

.non-scrollable-drawer {
  height: 100%;
  overflow-y: auto;
}
.v-overlay--active {
  backdrop-filter: blur(50px);
  z-index: 9999;
}
.titleText {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: white;
}

.titleMargin {
  margin-top: -50px;
}
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}

.glassTheme {
  background: #0d0d0d;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}

.subtitleText {
  font-size: 25px;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.modalSubtitleText {
  font-size: 15px;
  max-width: 450px;
  margin: auto;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.navigationDrawer {
  height: 10px;
}

.searchInput {
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  width: 500px;
  height: 40px;
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

input::placeholder {
  color: #ffffff;
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
}

.btn {
  padding: 8px;
  text-align: center;
  transition: 0.5s;
  font-family: "Poppins", sans-serif;
  background-size: 200% auto;
  color: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 120px;
}

.btnLogin {
  font-family: "Poppins", sans-serif;
  width: 120px;
  text-align: center;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: rgb(17, 169, 235);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.btnConnect {
  font-family: "Poppins", sans-serif;
  width: auto;
  text-align: center;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: rgb(195, 17, 235);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.btnGradient {
  /* font-size: 20px; */
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 120px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

.loginBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  /* background-image: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%); */
}

.signupBtn {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: 180px;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: rgb(17, 169, 235);
  /* background-image: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%); */
}

.btn-3 {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
}

.btnRequest {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  width: auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: rgb(17, 169, 235);
}

.btnSubmit {
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  /* width: 240px; */
  /* font-size: 20px; */
  font-family: "Poppins", sans-serif;
  text-align: center;
  /* width: 180px; */
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  /* padding-bottom: 10px; */
  /* height: 40px; */
  /* background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%); */
}

@media screen and (max-width: 600px) {
  .searchInput {
    width: 75%;
    font-size: 12px;
  }

  .spaceContainer {
    display: none;
  }

  .logoContainer {
    display: none;
  }
}
</style>
