<template>
  <v-card class="glassmorphism text-white mb-6">
    <v-card-title class="text-h5 mb-2">Future Projections</v-card-title>
    <v-card-text>
      <LineChart
        v-if="chartData"
        :data="chartData"
        :options="chartOptions"
        height="300"
      />
      <p v-else>No data available for predictions.</p>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from "vue";
import { Line as LineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

const props = defineProps({
  projectedStats: {
    type: Array,
    required: true
  },
});

const parseProjectedStats = (statsString) => {
  try {
    return JSON.parse(statsString);
  } catch (e) {
    console.error("Error parsing projected stats:", e);
    return {};
  }
};

const chartData = computed(() => {
  if (!props.projectedStats || props.projectedStats.length === 0) return null;

  const spotifyStats = props.projectedStats.find(stat => stat.platform.name === "Spotify")?.projectedStats;
  const appleStats = props.projectedStats.find(stat => stat.platform.name === "Apple Music")?.projectedStats;

  if (!spotifyStats || !appleStats) return null;

  const spotifyData = parseProjectedStats(spotifyStats);
  const appleData = parseProjectedStats(appleStats);

  const labels = Object.keys(spotifyData);

  return {
    labels,
    datasets: [
      {
        label: "Spotify",
        data: labels.map(label => spotifyData[label] || 0),
        borderColor: "#1DB954",
        backgroundColor: "rgba(29, 185, 84, 0.2)",
      },
      {
        label: "Apple Music",
        data: labels.map(label => appleData[label] || 0),
        borderColor: "#FA243C",
        backgroundColor: "rgba(250, 36, 60, 0.2)",
      },
    ],
  };
});

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Projected Streams",
      },
    },
    x: {
      title: {
        display: true,
        text: "Month",
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          label += context.parsed.y.toLocaleString() + " streams";
          return label;
        },
      },
    },
  },
};
</script>