<template>
  <v-card
    max-height="450"
    max-width="250"
    class="playcard elevation"
    @click="navigateToNFT(song.collection.id, song.id, song.nfttokenid)"
  >
    <div v-if="currentNftLoading || songLoading" class="mainProgressCircle">
      <v-progress-circular
        indeterminate
        color="rgb(255, 123, 0)"
        :size="50"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="mx-auto text-center">
        <img class="songImage" :src="imageUrl" alt="logo" />
      </div>
      <div class="pl-4 pr-1 pb-2" style="width: 100%">
        <div class="d-flex mt-1" style="gap: 5px">
          <img class="artistImage" :src="artistImage" alt="logo" />
          <p class="text-white subText mt-1" style="opacity: 0.6">
            {{ artist.name }}
          </p>
        </div>
        <p class="text-white titleText truncate">{{ song.name }}</p>
        <p class="text-white subText" style="opacity: 0.8">
          Streaming share: {{ song.royaltyPercentage }}%
        </p>
        <!-- <p class="text-white subText" style="opacity: 0.6">Share: 5%</p> -->
        <!-- <p class="text-white subText" style="opacity: 0.6">Terms: 30 years</p> -->
      </div>
      <v-card-actions
        class="button-container"
        style="padding: 0; margin-bottom: -5px"
      >
        <v-btn block class="btn text-center">
          <span v-if="nftMarketplaceDetails.listedForSale"
            >R {{ song.price }}</span
          >
          <span v-if="!nftMarketplaceDetails.listedForSale">Sold</span>
          <!-- <p>(R500)</p> -->
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import router from "../router";
import { ethers } from "ethers";
import Marketplace from "../Marketplace.json";

export default {
  name: "RecentDropCard",
  props: {
    nftData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentNftLoading = ref(false);
    const nftMarketplaceDetails = ref({});
    const artist = ref({});

    let imageUrl = process.env.VUE_APP_MEDIA_API_URL + props.nftData.image;
    const artistImage = ref(null);
    const song = ref(props.nftData);
    const price = ref(null);
    const image = ref(imageUrl);
    const maxLength = 16;
    // const truncatedText = computed(function () {
    //   return metadata.value.nftDescription.length > maxLength
    //     ? metadata.value.nftDescription.substring(0, maxLength) + "..."
    //     : metadata.value.nftDescription;
    // });

    onMounted(() => {
      getCurrentNFT(props.nftData.nfttokenid);
      refetch();
    });

    const {
      result,
      loading: songLoading,
      error,
      refetch,
    } = useQuery(
      gql`
        query artistByCollectionid($id: Int!) {
          artistByCollectionid(collectionId: $id) {
            id
            name
            user {
              profilePicture
            }
          }
        }
      `,
      {
        id: Number(song.value.collection.id),
      }
    );

    watch(result, (value) => {
      artist.value = value.artistByCollectionid[0];
      const userObject = artist.value.user;
      artistImage.value =
        process.env.VUE_APP_MEDIA_API_URL + userObject.profilePicture;
    });

    const navigateToNFT = (collectionId, nftBackendId, nftTokenId) => {
      router.push({
        path: `/collection/${collectionId}/${nftBackendId}/${nftTokenId}`,
      });
    };

    async function getCurrentNFT(token_id) {
      currentNftLoading.value = true;
      try {
        const provider = new ethers.JsonRpcProvider(
          process.env.VUE_APP_ALCHEMY_API_URL
        );
        let market = JSON.parse(Marketplace);
        //Pull the deployed contract instance
        let contract = new ethers.Contract(
          market.address,
          market.abi,
          provider
        );

        nftMarketplaceDetails.value = await contract.getListing(token_id);
        currentNftLoading.value = false;
      } catch (error) {
        currentNftLoading.value = false;
        console.log(error);
        alert("Error please reload page");
      }
    }

    return {
      songLoading,
      artist,
      navigateToNFT,
      image,
      song,
      imageUrl,
      artistImage,
      nftMarketplaceDetails,
      currentNftLoading,
      // truncatedText,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");

.elevation {
  /* Set initial box shadow and transform values */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  transition: all 0.15s ease-out;
}

.mainProgressCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px; /* 100% of the viewport height */
  width: 250px;
}

.button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.elevation:hover {
  /* Change box shadow and transform values on hover */
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.3);
  transform: translate(0, -5px);
}
.playcard {
  height: 350px;
  background: #0d0d0d;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 28px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(10px);
}

.subText {
  font-size: 15px;
}

.songImage {
  height: 180px;
  width: 180px;
  object-fit: cover;
}
.titleText {
  font-size: 19px;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.gradientColor {
  color: #57f2cc;
}
.btn {
  font-size: 16px;
  text-align: center;
  background-size: 200% auto;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: 5px;
  /* box-shadow: 0 0 2px #eee; */
  /* border-radius: 20px; */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
  justify-self: center;
  justify-items: center;
}

.playbutton {
  font-size: 60px;
}

.artistImage {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.playerColor {
  height: 2px;
  background: linear-gradient(90deg, #4136f1 -0.68%, #8743ff 100.7%);
}

@media screen and (max-width: 600px) {
  .playcard {
    width: 170px;
    height: 330px;
  }

  .mainProgressCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 310px; /* 100% of the viewport height */
    width: 170px;
  }

  .songImage {
    height: 175px;
    width: 175px;
  }

  .subText {
    font-size: 11px;
  }

  .titleText {
    font-size: 13px;
  }

  .btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .playcard {
    width: 250px;
  }
}

/* .title {
    font-size: 30px;
  } */
</style>
