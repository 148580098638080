<template>
  <v-row justify="center" class="mt-8">
    <v-col cols="12" md="6">
      <v-card class="glassmorphism text-white mb-6">
        <v-card-title class="text-h5 mb-2"
          >Weekly Streaming Analytics</v-card-title
        >
        <v-card-text>
          <LineChart
            v-if="weeklyChartData"
            :data="weeklyChartData"
            :options="chartOptions"
            height="250"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card class="glassmorphism text-white mb-6">
        <v-card-title class="text-h5 mb-2"
          >Monthly Streaming Analytics</v-card-title
        >
        <v-card-text>
          <LineChart
            v-if="monthlyChartData"
            :data="monthlyChartData"
            :options="chartOptions"
            height="250"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <PredictionChart :projectedStats="streamingStatsByCollection" />
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, computed, onMounted, toRefs, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

import PredictionChart from "./PredictionChart.vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);
const props = defineProps({
  collectionId: {
    type: Number,
    required: true,
  },
});

const { collectionId } = toRefs(props);
const streamingStatsByCollection = ref([])

const GET_STREAMING_STATS = gql`
  query GetStreamingStats($collectionId: Int!) {
    streamingStatsByCollection(collectionId: $collectionId) {
      platform {
        name
      }
      weeklyStats
      monthlyStats
      projectedStats
    }
  }
`;

console.log("collections: " + collectionId.value);

const { result, loading, error } = useQuery(GET_STREAMING_STATS, () => ({
  collectionId: Number(collectionId.value),
}));

watch( result, (value) => {
  if (value && value.streamingStatsByCollection) {
    streamingStatsByCollection.value = value.streamingStatsByCollection;
    console.log("New streaming stats:", value);
  }
});


const createChartData = (labels, spotifyData, appleData) => ({
  labels,
  datasets: [
    {
      label: "Spotify Streams",
      data: spotifyData,
      borderColor: "#1DB954",
      backgroundColor: "rgba(29, 185, 84, 0.2)",
    },
    {
      label: "Apple Music Streams",
      data: appleData,
      borderColor: "#FA243C",
      backgroundColor: "rgba(250, 36, 60, 0.2)",
    },
  ],
});

const weeklyChartData = computed(() => {
  if (!result.value) return null;

  // Find Spotify and Apple Music weekly stats
  const spotifyStatsString = result.value.streamingStatsByCollection.find(
    (stat) => stat.platform.name === "Spotify"
  )?.weeklyStats;

  const appleStatsString = result.value.streamingStatsByCollection.find(
    (stat) => stat.platform.name === "Apple Music"
  )?.weeklyStats;

  const spotifyStats =
    typeof spotifyStatsString === "string"
      ? JSON.parse(spotifyStatsString)
      : spotifyStatsString;
  const appleStats =
    typeof appleStatsString === "string"
      ? JSON.parse(appleStatsString)
      : appleStatsString;

  if (!spotifyStats) return null; // Handle case where Spotify stats are not found

  // Extract labels (dates) and data (stream counts)
  const labels = Object.keys(spotifyStats);
  const spotifyData = Object.values(spotifyStats);
  const appleData = labels.map((date) =>
    appleStats ? appleStats[date] || 0 : 0
  );

  const totalSpotifyStreams = spotifyData.reduce(
    (sum, value) => sum + value,
    0
  );
  return createChartData(labels, spotifyData, appleData);
});

const monthlyChartData = computed(() => {
  if (!result.value || !result.value.streamingStatsByCollection) return null;

  // Find Spotify and Apple Music monthly stats
  const spotifyStatsString = result.value.streamingStatsByCollection.find(
    (stat) => stat.platform.name === "Spotify"
  )?.monthlyStats;

  const appleStatsString = result.value.streamingStatsByCollection.find(
    (stat) => stat.platform.name === "Apple Music"
  )?.monthlyStats;

  // Parse JSON strings if necessary
  const spotifyStats =
    typeof spotifyStatsString === "string"
      ? JSON.parse(spotifyStatsString)
      : spotifyStatsString;
  const appleStats =
    typeof appleStatsString === "string"
      ? JSON.parse(appleStatsString)
      : appleStatsString;

  if (!spotifyStats) return null; // Handle case where Spotify stats are not found

  // Extract labels (dates) and data (stream counts)
  const labels = Object.keys(spotifyStats);
  const spotifyData = Object.values(spotifyStats);
  const appleData = labels.map((date) =>
    appleStats ? appleStats[date] || 0 : 0
  );

  // Log the monthly stats for debugging
  console.log("Spotify Monthly:", spotifyStats);
  console.log("Apple Monthly:", appleStats);

  // Create and return chart data
  return createChartData(labels, spotifyData, appleData);
});

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Number of Streams",
      },
    },
    x: {
      title: {
        display: true,
        text: "Time Period",
      },
    },
  },
};
</script>
<style scoped>
.glassmorphism {
  background: #0d0d0d;
  border-radius: 30px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  backdrop-filter: blur(80px);
}
</style>
