<template>
    <v-dialog v-model="dialogModel" max-width="600" class="editDialog mx-auto">
      <v-card class="rounded-xl glassmorphismDialogue elevation-15 text-white">
        <div class="mx-auto">
          <p class="text-h5 text-center mt-3">Settings</p>
          <div class="mx-4">
            <v-list lines="three" class="transparent-list text-white">
              <v-list-subheader class="text-white">Account</v-list-subheader>
  
              <v-list-item @click="editProfile">
                <template v-slot:prepend>
                  <v-avatar color="purple">
                    <v-icon color="white">mdi-account</v-icon>
                  </v-avatar>
                </template>
                <v-list-item-title class="custom-list-item-title"
                  >Profile</v-list-item-title
                >
                <v-list-item-subtitle class="custom-list-item-subtitle">
                  Update your profile and information
                </v-list-item-subtitle>
              </v-list-item>
  
              <v-list-item @click="editBanking">
                <template v-slot:prepend>
                  <v-avatar color="blue">
                    <v-icon color="white">mdi-bank</v-icon>
                  </v-avatar>
                </template>
                <v-list-item-title class="custom-list-item-title"
                  >Banking details</v-list-item-title
                >
                <v-list-item-subtitle class="custom-list-item-subtitle">
                  Update banking details to receive royalty streaming share
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
  
            <v-divider></v-divider>
  
            <v-list
              lines="three"
              select-strategy="classic"
              class="transparent-list text-white"
            >
              <v-list-subheader class="text-white">Blockchain</v-list-subheader>
  
              <v-list-item value="notifications">
                <v-list-item-action start>
                  <v-checkbox-btn
                    v-model="web3EnabledModel"
                    @change="toggleWeb3"
                  ></v-checkbox-btn>
                </v-list-item-action>
  
                <v-list-item-title class="custom-list-item-title"
                  >Blockchain view</v-list-item-title
                >
                <v-list-item-subtitle class="custom-list-item-subtitle">
                  Show web3 features and my blockchain info
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    name: 'SettingsDialog',
    props: {
      modelValue: Boolean,
      web3Enabled: Boolean,
    },
    emits: ['update:modelValue', 'editProfile', 'editBanking', 'toggleWeb3'],
    setup(props, { emit }) {
      const dialogModel = ref(props.modelValue);
      const web3EnabledModel = ref(props.web3Enabled);
  
      watch(() => props.modelValue, (newValue) => {
        dialogModel.value = newValue;
      });
  
      watch(dialogModel, (newValue) => {
        emit('update:modelValue', newValue);
      });
  
      watch(() => props.web3Enabled, (newValue) => {
        web3EnabledModel.value = newValue;
      });
  
      const editProfile = () => {
        emit('editProfile');
        dialogModel.value = false;
      };
  
      const editBanking = () => {
        emit('editBanking');
        dialogModel.value = false;
      };
  
      const toggleWeb3 = (event) => {
        emit('toggleWeb3', event);
      };
  
      return {
        dialogModel,
        web3EnabledModel,
        editProfile,
        editBanking,
        toggleWeb3,
      };
    },
  };
  </script>
  
  <style scoped>
  .editDialog {
    width: 600px;
  }
  
  .glassmorphismDialogue {
    background: #0d0d0d;
    border-radius: 30px;
    background: linear-gradient(
      108.46deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.03) 100%
    );
    backdrop-filter: blur(80px);
    min-height: 300px;
    width: 600px;
  }
  
  .transparent-list {
    background-color: transparent !important;
  }
  
  .custom-list-item-title {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  
  .custom-list-item-subtitle {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
  }
  
  @media screen and (max-width: 600px) {
    .editDialog,
    .glassmorphismDialogue {
      width: 95%;
    }
  }
  </style>