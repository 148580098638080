<template>
  <v-app class="mainContainer">
    <v-main class="appbackground">
    <nav-bar class="navbarContainer"></nav-bar>

    <!-- <nav-bar class="navbarContainer"></nav-bar> -->
      <router-view />
      <!-- <div class="purpleShadow"></div> -->
      <BottomNavbar class="BottomNavbarContainer"></BottomNavbar>
    </v-main>
  </v-app>
</template>

<script>
// import BottomFooter from "./components/BottomFooter.vue";
import NavBar from "./components/NavBar.vue";
import BottomNavbar from "./components/BottomNavbar.vue";
import { onMounted } from "vue";
import { Magic } from "magic-sdk";
import { useAuthenticationStore } from "./stores/authentication";

export default {
  name: "App",

  components: {
    NavBar,
    BottomNavbar
  },

  setup() {
    const userAuth = useAuthenticationStore();
    const { clearCookies } = userAuth;

    onMounted(() => {
      checkLogin();
    });

    const checkLogin = async () => {
      const magic = new Magic(process.env.VUE_APP_MAGIC_API_KEY);

      try {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (!isLoggedIn) {
          await magic.user.logout();
          clearCookies();
        }
      } catch {
        // Handle errors if required!
      }
    };

  },
};
</script>
<style>
.appbackground {
  background-color: #191919;
  max-width: 100%;
  overflow-x: hidden;
}

.navbarContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: -50px;
}

/* .BottomNavbarContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: -50px;
} */


.mainContainer {
  max-width: 100%;
  background-color: #191919;
  overflow-x: hidden;
}
</style>
