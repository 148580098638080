<template>
    <div class="bioContainer mt-10">
      <div class="d-flex">
        <img
          class="songImage"
          :src="profilePicture || 'https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/defaultProfile.svg'"
          alt="logo"
        />
        <v-btn
          v-if="isAccountOwner && isAuthenticated"
          class="editIcon"
          variant="text"
          icon="mdi-cog-outline"
          @click="$emit('openSettings')"
        ></v-btn>
      </div>
      <v-row class="mt-6">
        <v-col cols="12" md="7">
          <div class="profileDetails">
            <div class="d-flex align-center mt-3">
              <p class="headingText text-white">{{ fanData.username }}</p>
              <div class="ml-6 mt-2">
                <p class="followerText d-flex" @click="$emit('showFollowers')">
                  <span>
                    <v-icon size="21" class="mb-1 mr-2" color="rgb(255, 123, 0)">mdi-account</v-icon>
                  </span>
                  <span>{{ followersCount }}</span>
                </p>
              </div>
            </div>
            <p class="titleText">Owned music assets: {{ nftCount }}</p>
            <p class="text-grey">Date joined: {{ fanData.dateJoined }}</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserProfile",
    props: {
      profilePicture: String,
      isAccountOwner: Boolean,
      isAuthenticated: Boolean,
      fanData: Object,
      followersCount: Number,
      nftCount: Number,
    },
    emits: ['openSettings', 'showFollowers'],
  };
  </script>
  
  <style scoped>
  .bioContainer {
    padding-left: 25px;
  }
  
  .songImage {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 15%;
  }
  
  .editIcon {
    font-size: 25px;
    margin-left: 35px;
    color: white;
  }
  
  .profileDetails {
    z-index: 2;
  }
  
  .headingText {
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    color: rgb(255, 123, 0);
    z-index: 2;
  }
  
  .followerText {
    font-size: 19px;
    font-family: "Poppins", sans-serif;
    color: white;
  }
  
  .titleText {
    font-size: 19px;
    font-family: "Poppins", sans-serif;
    line-height: 1.5;
    color: white;
  }
  
  @media screen and (max-width: 600px) {
    .headingText {
      font-size: 32px;
    }
  }
  </style>