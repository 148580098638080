<template>
  <v-app>
    <v-main class="appbackground">
      <div class="glassEffect"></div>
      <div class="imageParent">
        <!-- <img class="songImage" src="/images/lue.png" alt="logo" /> -->
      </div>
      <v-container class="detailsContainer">
        <p class="headingText">Roses and Dice</p>
        <p class="titleText widthContainer">
          Buy Roses and Dice album NFTs and get streaming royalties with LuE
        </p>
        <p class="subtitleText mb-10 mt-4">Artist: Joe Mothusi</p>

        <div class="d-flex mt-10" style="gap: 20px">
          <div>
            <p class="titleText priceColor">R800</p>
            <p class="subtitleText priceColor">Start Price</p>
          </div>

          <div>
            <p class="titleText priceColor">240k+</p>
            <p class="subtitleText priceColor">Total Sale</p>
          </div>

          <div>
            <p class="titleText priceColor">240k+</p>
            <p class="subtitleText priceColor">Streams</p>
          </div>
        </div>
      </v-container>

      <v-card
        max-width="1200"
        class="mx-auto collectionContainer"
        flat
        color="transparent"
      >
        <p class="text-white headingText text-center">NFT COLLECTION</p>
        <div v-if="!loadings" class="nftcollection-grid mt-4">
          <div v-for="i in 15" :key="i">
            <NFTCard :nftData="testNFT"></NFTCard>
          </div>
        </div>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import NFTPlay from "../components/NFTPlay.vue";
import NFTCard from "../components/NFTCard.vue";
import { ref, onMounted } from "vue";
import Marketplace from "../Marketplace.json";
import { ethers } from "ethers";
import axios from "axios";

export default {
  name: "CollectionPage",

  components: {
    NFTPlay,
    NFTCard,
  },

  setup() {
    const allNFTs = ref([]);
    const testNFT = ref({});
    const tab = ref(null);
    const loadings = ref(true);

    onMounted(() => {
      getAllNFTs();
    });

    async function getAllNFTs() {
      //After adding your Hardhat network to your metamask, this code will get providers and signers
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      //Pull the deployed contract instance
      let market = JSON.parse(Marketplace);
      let contract = new ethers.Contract(market.address, market.abi, signer);
      //create an NFT Token
      let transaction = await contract.getAllNFTs();
      console.log(transaction);
      //Fetch all the details of every NFT from the contract and display
      const items = await Promise.all(
        transaction.map(async (i) => {
          const tokenURI = await contract.tokenURI(i.tokenId);
          console.log(tokenURI);
          let meta = await axios.get(tokenURI, {
            headers: {
              Accept: "text/plain",
            },
          });
          meta = meta.data;

          let price = ethers.utils.formatUnits(i.price.toString(), "ether");
          let item = {
            price,
            tokenId: i.tokenId.toNumber(),
            seller: i.seller,
            owner: i.owner,
            image: meta.image,
            nftName: meta.nftName,
            nftDescription: meta.nftDescription,
          };
          return item;
        })
      );
      testNFT.value = items[1];
      allNFTs.value = items;
      loadings.value = false;
    }

    return {
      tab,
      testNFT,
      loadings,
      allNFTs,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

.appbackground {
  background-color: #11001d;
}
.collectionContainer {
  height: 100%;
  margin-top: 430px;
}
.detailsContainer {
  height: 100%;
  position: absolute;
  margin-top: -25px;
}

.widthContainer {
  width: 50%;
}
.imageParent {
  position: relative;
}
.nftcollection-grid {
  display: grid;
  gap: 1.5rem;
  /* grid-template-columns: repeat(4,1fr); */
  width: 90%;
  margin-inline: auto;
}

/* @media screen and (max-width: 400px) {
  .nftcollection-grid{
    grid-template-columns: repeat(1,1fr);

  }
} */

@media screen and (min-width: 380px) {
  .nftcollection-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 800px) {
  .nftcollection-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .nftcollection-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.titleText {
  font-size: 27px;
  font-family: "Aldrich", serif;
  line-height: 1.5;
  color: white;
}
.headingText {
  font-size: 40px;
  font-family: "Aldrich", serif;
  line-height: 1.5;
  color: white;
}
.subtitleText {
  font-size: 18px;
  margin-top: 3px;
  font-family: "Aldrich", serif;
  color: white;
}

.songImage {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: -200px;
  backdrop-filter: blur(10px);
  max-height: 320px;
  width: 320px;
  object-fit: cover;
  border-radius: 100%;
}

.royaltystats {
  width: 100%;
  background: rgba(59, 59, 59, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.tabStyle {
  font-size: 25px;
  color: white;
}

.visitBtn {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.priceColor {
  background: linear-gradient(225deg, #54ffb3 0%, #57f2cc 47.87%, #4596fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
}

.buyBtn {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
  text-align: center;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 2px #eee;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(225deg, #a259ff 0%, #a259ff 0.01%, #4596fb 100%);
}

@media screen and (max-width: 600px) {
  .songImage {
    max-height: 180px;
    width: 180px;
    left: 50%;
    transform: translate(-50%, 0);
    object-fit: cover;
    border-radius: 100%;
  }

  .titleText {
    font-size: 16px;
  }

  .headingText {
    font-size: 25px;
    margin-top: 35px;
  }

  .widthContainer {
    width: 75%;
  }

  .subtitleText {
    font-size: 14px;
  }

  .glassEffect {
    height: 260px;
    width: 100%;
    background-size: cover;
    margin-top: -50px;
  }

  .collectionContainer {
    height: 100%;
    margin-top: 280px;
  }
}
</style>
