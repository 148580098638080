require("dotenv").config();
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import "@mdi/font/css/materialdesignicons.css";
import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Carousel3d from 'vue-carousel-3d'
// import dotenv from 'dotenv';

const vuetify = createVuetify({
    components,
    directives,
  })
  
  const pinia = createPinia()

  const apolloClient = new ApolloClient({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_BACKEND_API_URL + 'universal/alldata/popsyman',
    cache: new InMemoryCache(),
  })
  AOS.init();
  const app = createApp(App)
  app.use(router)
  app.use(pinia)
  app.use(vuetify)
  app.use(Carousel3d);
  app.provide(DefaultApolloClient, apolloClient);
  
  app.mount('#app')

  router.afterEach(() => {
    AOS.refresh();
  });
  
