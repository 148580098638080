<template>
  <div class="mainContainer">
    <v-container>
      <h1 class="my-6 text-center">Gummint</h1>
      <v-row justify="center" class="mt-8">
        <v-img
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/passwordreset.gif"
          max-width="250"
          max-height="250"
        ></v-img>
      </v-row>
      <div v-if="showReset">
        <v-alert
          v-model="errorAlert"
          dense
          dismissible
          outlined
          type="error"
          class="mt-10 mx-auto"
          style="max-width: 500px"
        >
          {{ errorMessage }}
        </v-alert>
        <h2 class="text-center font-weight-light mt-8 mb-4">
          Reset your password?
        </h2>
        <h4 class="text-center font-weight-light mt-4 mb-4">
          Enter your new password below
        </h4>
        <v-row justify="center" class="mt-4 px-4">
          <v-text-field
            class="mx-8 rounded-lg"
            style="max-width: 300px"
            max-width="100px"
            outlined
            Dense
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            label="Enter new password"
            clearable
            @click:append="show = !show"
          ></v-text-field>
        </v-row>

        <v-row justify="center">
          <v-btn
            color="#F24296"
            @click="resetPassword()"
            rounded
            class="mt-4 mb-8"
            type="submit"
            dark
          >
            <div v-if="resetLoading">
              Resetting
              <v-progress-circular
                class="ml-4"
                :size="21"
                indeterminate
                color="white"
              ></v-progress-circular>
            </div>
            <div v-else>Reset password</div>
          </v-btn>
        </v-row>
      </div>
      <div v-else>
        <h2 class="text-center font-weight-light mt-8 mb-4">
          Reset successfully
        </h2>
        <h4 class="text-center font-weight-light mt-4 mb-4">
          You can now close this page and log in to the application
        </h4>
      </div>
    </v-container>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Password successfully changed
        </v-card-title>

        <v-img
          class="mx-auto"
          src="https://gummintbucket.s3.af-south-1.amazonaws.com/assets/images/reset.gif"
          max-width="280"
          max-height="280"
        ></v-img>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="#F24296" text @click="doneReset()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  setup() {
    const route = useRoute();
    const password = ref("");
    const token = ref("");
    const resetLoading = ref(false);
    const showReset = ref(true);
    const errorAlert = ref(false);
    const errorMessage = ref("");
    const dialog = ref(false);
    const show = ref(false);

    onMounted(() => {
      token.value = route.params.token;
      console.log(token.value);
    });

    const doneReset = () => {
      dialog.value = false;
    };

    const resetPassword = () => {
      if (password.value.length < 8) {
        errorAlert.value = true;
        errorMessage.value =
          "Password is too short, please enter a password with 8 characters or more.";
      } else {
        resetLoading.value = true;

        //   let passwordToken = token.value.substring(32);
        let formData = new FormData();
        formData.append("token", token.value);
        formData.append("password", password.value);

        axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL + `api/password_reset/confirm/`,
            formData
          )
          .then((response) => {
            resetLoading.value = false;
            showReset.value = false;
            dialog.value = true;

            console.log(response);
          })
          .catch((error) => {
            resetLoading.value = false;
            errorAlert.value = true;
            errorMessage.value = "Network error, please try again";
            console.log(error.responses);
          });
      }
    };

    return {
      password,
      token,
      resetLoading,
      showReset,
      errorAlert,
      errorMessage,
      dialog,
      show,
      doneReset,
      resetPassword,
    };
  },
};
</script>

<style scoped>
.mainContainer {
  background-color: white;
  height: 100%;
}
</style>
